import * as StarAPI from '../../apis/star'
import appConfig from 'config'

import { SinguSubtitleOverlay } from './subtitles'
import { gaEvent } from '../../core/services/tracking'

let isBMPScriptPresent = false;
let players = [];

export function onBMPReady(callback) {
	// Add BMP script if not already added
	if (!isBMPScriptPresent) {
		addBMPScript();
		isBMPScriptPresent = true;
	}

	// Wait for BMP to load
	const interval = setInterval(() => {
		if (isBMPLoaded()) {
			clearInterval(interval);
			callback();
		}
	}, 10);
}

export function waitForBMP() {
	return new Promise((resolve) => {
		onBMPReady(() => {
			resolve();
		});
	});
}

export async function initPlayer({ playerRef, config, source, asset, enableDownload, enableEndcreditJump, assetInfoForAnalytics = {} }) {
	await waitForBMP();
	console.log(config, source);

	// ANALYTICS: expose video metadata to the analytics system (as soon as it is available, before the videoplayer)
	gaEvent("videometadata", { config, source, ...assetInfoForAnalytics });

	const bm = window.bitmovin;

	// Start the playback
	const player = new bm.player.Player(playerRef, config);
	player.load(source);

	// Player UI
	const subtitlesToggleButton = new bm.playerui.Button({
		text: "Toggle subtitles",
		cssClass: "ui-subtitlesettingstogglebutton bmpui-on"
	});
	const downloadButton = new bm.playerui.Button({ text: "DL", cssClasses: "custom-button", hidden: !enableDownload });
	const endCreditButton = new bm.playerui.Button({ text: "EC", cssClasses: "custom-button", hidden: !enableEndcreditJump || !asset.endCreditTimestamp });
	const playerUI = new bm.playerui.UIManager(player, new bm.playerui.UIContainer({
		components: [
			new SinguSubtitleOverlay((overlay) => { overlay.hook(player) }),
			new bm.playerui.BufferingOverlay(),
			new bm.playerui.PlaybackToggleOverlay(),
			new bm.playerui.CastStatusOverlay(),
			new bm.playerui.ControlBar({
				components: [
					new bm.playerui.Container({
						components: [
							new bm.playerui.PlaybackTimeLabel({
								timeLabelMode: bm.playerui.PlaybackTimeLabelMode.CurrentTime
							}),
							new bm.playerui.SeekBar({ label: new bm.playerui.SeekBarLabel() }),
							new bm.playerui.PlaybackTimeLabel({
								timeLabelMode: bm.playerui.PlaybackTimeLabelMode.TotalTime,
								cssClasses: ["text-right"]
							}),
						],
						cssClasses: ["controlbar-top"],
					}),
					new bm.playerui.Container({
						components: [
							new bm.playerui.QuickSeekButton({ seekSeconds: -10 }),
							new bm.playerui.PlaybackToggleButton(),
							new bm.playerui.QuickSeekButton({ seekSeconds: 10 }),
							endCreditButton,
							new bm.playerui.VolumeToggleButton(),
							new bm.playerui.VolumeSlider(),
							new bm.playerui.Spacer(),
							downloadButton,
							new bm.playerui.PictureInPictureToggleButton(),
							new bm.playerui.AirPlayToggleButton(),
							new bm.playerui.CastToggleButton(),
							subtitlesToggleButton,
							new bm.playerui.FullscreenToggleButton(),
						],
						cssClasses: ["controlbar-bottom"],
					}),
				],
			}),
			new bm.playerui.ErrorMessageOverlay(),
		],
	}));

	if (downloadButton && enableDownload) {
		downloadButton.addEventListener("click", () => {
			downloadVideo(asset.assetGuid);
		});
	}

	if (endCreditButton && enableEndcreditJump && asset.endCreditTimestamp) {
		endCreditButton.title = "Jump to end credits";
		endCreditButton.addEventListener("click", () => {
			player.seek(getTansformedTimestamp(asset.endCreditTimestamp));
		});
	}

    subtitlesToggleButton.element.elements[0].style.display = "none";
    subtitlesToggleButton.buttonEvents.onClick.subscribe(() => {
        if (player.subtitles.list()[0].enabled) {
            player.subtitles.disable("caption");
            subtitlesToggleButton.element.elements[0].className = "bmpui-ui-subtitlesettingstogglebutton";
        } else {
            player.subtitles.enable("caption");
            subtitlesToggleButton.element.elements[0].className = "bmpui-ui-subtitlesettingstogglebutton bmpui-on";
        }
    });

    // Enable subtitles if present
    player.on("subtitleadded", () => {
        // Might fail, but that's ok
        try {
            // Check if subtitles exist and then enable
            fetch(player.subtitles.list()[0].url, { method: "HEAD" })
                .then((res) => {
                    if (res.status === 200) {
						player.subtitles.enable("caption");
					}
                });
        } catch {}
    });

    player.on("subtitleenabled", () => {
        subtitlesToggleButton.element.elements[0].style.display = "";
    });

	// Handle errors gracefully
	player.on("error", ({ code, data }) => {
		console.error("Player Error:", code, data);
		console.log(playerUI);
		if (code === 2006) {
			playerUI.currentUi.getPlayer().fireEventInUI("error", {
				name: "Unsupported Browser\nPlease use Chrome, Edge, Firefox or any Chromium based browser.",
				code: "",
			});
		} else {
			playerUI.currentUi.getPlayer().fireEventInUI("error", {
				name: `Error ${code}\nAn unexpected error ocurred. Please try again later.`,
				code: "",
			});
		}
	});

	// ANALYTICS: track play state
	player.on("play", (playback) => {
		gaEvent("play", { playback });
	});
	
	// ANALYTICS: track playback errors
	player.on("error", (playback_error) => {
		gaEvent("error", { playback_error });
	});

	players.push(player);
	const playerIndex = players.length - 1;
	return playerIndex;
}

export async function destroyPlayer(playerIndex) {
	await waitForBMP();
	const player = players[playerIndex];
	if (player?.destroy) {
		player.destroy();
	}
	players.splice(playerIndex, 1);
}

function addBMPScript() {
	// BMP JS
	const script = document.createElement("script");
	script.onerror = e => console.error("BMP script onerror", e);
	script.async = true;
	script.src = "https://cdn.bitmovin.com/player/web/8/bitmovinplayer.js";
	document.head.appendChild(script);

	// BMP UI JS
	const uiscript = document.createElement("script");
	uiscript.onerror = e => console.error("BMP script onerror", e);
	uiscript.async = true;
	uiscript.src = "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js";
	document.head.appendChild(uiscript);

	// BMP CSS
	const link = document.createElement("link");
	link.rel = "stylesheet";
	link.href = "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css";
	document.head.appendChild(link);
}

function isBMPLoaded() {
	return window.bitmovin?.player?.Player !== undefined
		&& window.bitmovin?.playerui?.UIManager !== undefined;
}

// 00:10:05.33 => 605.33
function getTansformedTimestamp(timestamp) {
	if (!timestamp || !timestamp.length)
		return 0;
	
	const [hours, minutes, seconds] = timestamp.split(":").map(str => parseInt(str));
	return hours * 60 + minutes * 60 + seconds;
}

export async function downloadFile(assetGuid, fileId, convert) {
	const a = document.createElement("a");
	a.target = "_blank";
	const downloadLink = fileId
		? `${appConfig.api.star}assets/${assetGuid}/content`
		: await StarAPI.fetchAssetDownloadLink(assetGuid);
	const qParams = new URLSearchParams();
	if (fileId) {
		qParams.set("fileId", fileId);
		qParams.set("useContainerName", true); // Human-readable file name
	}
	if (convert) {
		qParams.set("convert", convert);
	}
	a.href = `${downloadLink}?${qParams.toString()}`;
	a.download = assetGuid;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export async function downloadVideo(assetGuid) {
	await downloadFile(assetGuid);
}