import cloneDeep from 'lodash/cloneDeep'
import alt from '../../core/services/alt'
import Moment from 'moment'

import Actions from './actions'
import EditorActions  from '../../components/editor/actions'
import StarContainerActions from '../star2/containereditor-metadata/actions'
import ProgramFamilyDialogActions from '../../dialogs/programFamilyDialog/actions'
import RatingsEditorActions from './ratingseditor/actions'

import { swapImageAsset } from './shared/utils'
import { addChannel, removeChannel } from '../internal-schedule/shared/utils'

import appConfig from 'config'

const tv4Channels = [
	{ group: "tv4", key: "tv4", text: "TV4" },
	{ group: "tv4", key: "sjuan", text: "Sjuan" },
	{ group: "tv4", key: "tv12", text: "TV12" },
	{ group: "tv4", key: "fakta", text: "TV4 Fakta" },
	{ group: "tv4", key: "tv4-guld", text: "TV4 Guld" },
	{ group: "tv4", key: "tv4-film", text: "TV4 Film" },
	{ group: "tv4", key: "sf-kanalen", text: "SF-kanalen" },
	{ group: "tv4", key: "tv4-sportkanalen", text: "TV4 Sportkanalen" },
	{ group: "tv4", key: "tv4-stars", text: "TV4 Stars SE" },
	{ group: "tv4", key: "tv4-hits", text: "TV4 Hits SE" },
	{ group: "tv4", key: "tv4-fotboll", text: "TV4 Fotboll" },
	{ group: "tv4", key: "tv4-hockey", text: "TV4 Hockey" },
	{ group: "tv4", key: "tv4-tennis", text: "TV4 Tennis" },
	{ group: "tv4", key: "tv4-motor", text: "TV4 Motor" },
	{ group: "tv4", key: "tv4-live-1", text: "TV4 Sport Live 1" },
	{ group: "tv4", key: "tv4-live-2", text: "TV4 Sport Live 2" },
	{ group: "tv4", key: "tv4-live-3", text: "TV4 Sport Live 3" },
	{ group: "tv4", key: "tv4-live-4", text: "TV4 Sport Live 4" },
];

const mtvChannels = [
	{ group: "mtv", key: "mtv3", text: "MTV3" },
	{ group: "mtv", key: "ava", text: "AVA" },
	{ group: "mtv", key: "sub", text: "Sub" },
	{ group: "mtv", key: "mtv-viihde", text: "MTV Viihde" },
	{ group: "mtv", key: "mtv-aitio", text: "MTV Aitio" },
	{ group: "tv4", key: "sf-kanalen", text: "SF-kanalen" },
	{ group: "mtv", key: "mtv-juniori", text: "MTV Juniori" },
	{ group: "mtv", key: "mtv-urheilu-1", text: "MTV Urheilu 1" },
	{ group: "mtv", key: "mtv-urheilu-2", text: "MTV Urheilu 2" },
	{ group: "mtv", key: "mtv-urheilu-3", text: "MTV Urheilu 3" },
	{ group: "mtv", key: "mtv-max", text: "MTV MAX" },
];

const filterStorageKey = "c6-metadata-store-filter";
const prevFilterStorageKey = "c6-metadata-store-filter-prev";
const schedulesFilterStorageKey = "c6-metadata-store-filter-schedules";

class MetadataStore {

	constructor() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
		this.masterTemplateModifications = new Map(); // Used in the UI to render episode template status before the set template job has run
		this.useChannelGroupsFallback = false;

		this.item = {
			program: {},
			family: {},
		};

		this.list = {};
		this.initStore("programs");
		this.initStore("pastProgramsNeedingAttention");
		this.initStore("pastProgramsMatchingSearch");
		this.initStore("families");
		this.initStore("tagPrograms");
		this.initStore("translations");
		this.initStore("channelGroups");

		// Series seasons
		this.initStore("seriesSeasonPrograms", {
			_selectedSeasonId: null, // Used in the UI to remember which season is selected
			approval: "",
			orderBy: "title",
		}, this.initSeriesSeasonPrograms());

		// Schedules
		const storedSchedulesFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(schedulesFilterStorageKey)): null;
		this.initStore("schedules", storedSchedulesFilters ?? {
			channels: "",
			include: "program",
			_approvalStatus: "",
			_type: "",
			_channelGroup: getDefaultChannelGroup(),
		});
		
		this.fallbackChannels = [];
		if (!appConfig.features.hideTV4Channels) {
			this.fallbackChannels.push(...tv4Channels);
		}
		if (!appConfig.features.hideMTVChannels) {
			this.fallbackChannels.push(...mtvChannels);
		}

		// TODO: Move selected to a specific list store in initStore
		this.selected = {
			programs: []
		};

		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)): null;
		this.filters = storedFilters ?? {
			searchText: "",
			filter: {
				_type: "",
				_translationStatus: "translate",
				_searchPastPrograms: false,

				rightVersion: "",
				premiere: "upcoming",
				approval: "missing",
				versionApproval: "generic",
				right: "",
				rightClass: "",
				filter: "",
				orderBy: "premiere",
			},
			pageSize: 50,
			hideWithParent: "",
			excludeattributes: "synopses,credits",
		};
		this.setProgramClassAndTypes(this.filters.filter._type);
		this.setOldSearchFiltering();

		const storedPrevFilter = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(prevFilterStorageKey)): null;
		this._prevFilter = storedPrevFilter ?? null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			// Master templates
			onUpdateTemplate: [Actions.SET_AS_DEFAULT_TEMPLATE, Actions.RELEASE_DEFAULT_TEMPLATE],
			onTemplateUpdated: Actions.TEMPLATE_UPDATED,
			onEpisodeTemplateStatusUpdated: [Actions.EPISODE_TEMPLATE_STATUS_UPDATED],

			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			// onItemSaved: [Actions.ITEM_UPDATED,EditorActions.MODEL_SAVED],
			// onItemSaved: Actions.ITEM_UPDATED,

			onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			onAddItemEntity: Actions.ADD_ITEM_ENTITY,
			onRemoveItemEntity: Actions.REMOVE_ITEM_ENTITY,

			onSetItemEntityProperty: Actions.SET_ITEM_ENTITY_PROPERTY,

			// Schedule
			onFetchScheduleDay: Actions.FETCH_SCHEDULE_DAY,
			onScheduleDayUpdated: Actions.SCHEDULE_DAY_UPDATED,
			onNavigation: [Actions.NAV_NEXT, Actions.NAV_PREV, Actions.NAV_TODAY, Actions.NAV_DATE],
			onSelectChannel: Actions.SELECT_CHANNEL,
			onUnselectChannel: Actions.UNSELECT_CHANNEL,
			onScheduleFilter: Actions.SCHEDULE_FILTER,

			// Seasons
			onFilterSeriesSeasons: Actions.FILTER_SERIES_SEASONS,
			onFetchSeriesSeasons: Actions.FETCH_SERIES_SEASONS,
			onSeriesSeasonDataLoaded: Actions.SERIES_SEASON_DATA_LOADED,
			onFetchSeasonEpisodes: Actions.FETCH_SEASON_EPISODES,
			onSetSelectedSeason: Actions.SET_SELECTED_SEASON,

			onNoSeasonsAvailable: Actions.NO_SEASONS_AVAILABLE,

			onConnectSeasonToSeries: Actions.CONNECT_SEASON_TO_SERIES,
			onDisconnectSeasonFromSeries: Actions.DISCONNECT_SEASON_FROM_SERIES,
			onSeriesConnectedToSeason: Actions.SERIES_CONNECTED_TO_SEASON,

			onFetchMasterTemplate: Actions.FETCH_MASTER_TEMPLATE,
			onMasterTemplateDataLoaded: Actions.MASTER_TEMPLATE_DATA_LOADED,

			// onViewChange: Actions.VIEW_CHANGE,
			onUnmount: Actions.UNMOUNT,

			// Custom actions
			itemUpdatedTransforms: Actions.ITEM_UPDATED,
			selectItem: Actions.SELECT_ITEM,
			clearSelected: Actions.CLEAR_SELECTED,
			removeTagProgram: Actions.REMOVE_TAG_PROGRAM,
			onFetchTagPrograms: Actions.FETCH_TAG_PROGRAMS,
			onFetchChannelGroups: Actions.FETCH_CHANNEL_GROUPS,
			onUseChannelGroupsFallback: Actions.USE_CHANNEL_GROUPS_FALLBACK,

			// External component actions go here
			onEditorSaved: EditorActions.MODEL_SAVED,
			onStarContainerApproved: StarContainerActions.APPROVE_CONTAINER,
			onStarContainerMainUpdated: StarContainerActions.MAIN_UPDATED,
			onFamilyMemberAdded: ProgramFamilyDialogActions.FAMILY_MEMBER_ADDED,
			onRatingsEditorSaved: RatingsEditorActions.METADATA_PROGRAM_SAVED,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
	}

	/* Filters */
	onSearch({ searchText: text, targetStore }) {
		// let { filter, orderBy, programTypes, hideWithParent, searchText } = this.filters;

		if (text === '') {
			this.filters.filter = cloneDeep(this._prevFilter);
			this._prevFilter = null;
			this.filters.hideWithParent = "";
			this.setOldButActiveFiltering(this.filters.filter);
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {};
			this.filters.filter.orderBy = 'title';
			this.filters.hideWithParent = "season";
		}
		this.filters.searchText = text;
		const type = this.filters.filter ? this.filters.filter._type : null;
		this.setProgramClassAndTypes(type);
		this.setOldSearchFiltering();
		this.list[targetStore || "programs"].items = [];
		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = {
			...filterParams,
			...filterPayload,
		};

		if (!appConfig.features.metadataNewDefaultSearchBehaviour || payload._searchPastPrograms === undefined) {
			// Reset filters used by searchText
			this.searchText = ""; // TODO: Remove?
			this.filters.searchText = "";
			this.filters.hideWithParent = "";

			this.unselectedFilter = false;
			this._prevFilter = null;
		}

		this.isLoading = true;
		this.list[targetStore || "programs"].items = [];
		const type = this.filters.filter ? this.filters.filter._type : null;
		this.setProgramClassAndTypes(type);
		this.setOldButActiveFiltering(this.filters.filter, filterPayload);
		this.setOldSearchFiltering();
		this.saveFiltersToStorage();
	}

	/* Master Episode Templates */
	onUpdateTemplate() {
		this.isLoading = true;
	}
	onTemplateUpdated({ model, parentId }) {
		this.updateStore("seriesSeasonPrograms", model);

		// Add the seasonId to our temporary template modification set so the UI can display
		// that episodes will get or loose a template eventually (when the api job runs)
		const mtm = this.masterTemplateModifications;

		if (model.isMasterTemplate) {
			mtm.set(parentId, {
				event: "added",
				masterId: model.id,
				time: new Date(),
			});
		}
		else {
			mtm.set(parentId, {
				event: "removed",
				masterId: model.id,
				time: new Date(),
			});

			// In case we're only listing one episode (like we do when we click in
			// the linear schedule) make sure we delete the master template item
			// from the list
			const templateIndex = this.list.seriesSeasonPrograms.items.findIndex(i => i.type === "Template");
			if (templateIndex >= 0) {
				this.list.seriesSeasonPrograms.items.splice(templateIndex, 1);
			}
		}
		this.isLoading = false;
	}
	onEpisodeTemplateStatusUpdated({ model, }) {
		this.updateStore("seriesSeasonPrograms", model);
		this.isLoading = false;
	}

	/* Schedule */
	onFetchScheduleDay({ requestTime }) {
		this.isLoading = true;
		const l = this.list.schedules;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
	}

	onScheduleDayUpdated({ datastore, items, numberOfItems, requestTime }) {
		const l = this.list.schedules;
		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", datastore, numberOfItems);
		}
		else {
			l.items = items;
			l.numberOfItems = numberOfItems;
			this.isLoading = false;
		}
	}
	onNavigation() {
		// this.isLoading = true;
	}

	onSelectChannel(channelsToAdd) {
		this.list.schedules.filters.channels = addChannel(channelsToAdd, this.list.schedules.filter.channels);
		this.saveFiltersToStorage();
	}

	onUnselectChannel(channelsToRemove) {
		this.list.schedules.filters.channels = removeChannel(channelsToRemove, this.list.schedules.filter.channels);
		this.saveFiltersToStorage();
	}

	onScheduleFilter(payload) {
		this.list.schedules.filters = {
			...this.list.schedules.filters,
			...payload,
		};
		this.saveFiltersToStorage();
	}

	/* Items */
	onFetchItems({ store, requestTime }) {
		if (store !== "pastProgramsNeedingAttention" && store !== "pastProgramsMatchingSearch") {
			this.isLoading = true;
		}
		const l = this.list[store];

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		Object.keys(this.selected).forEach(type => this.selected[type] = []);
		l.latestRequestTime = requestTime;
	}

	onPageItems({ entity, store }) {
		this.isLoading = true;
		const l = this.list[store || entity];
		l.nextPageUrl = null;
	}

	onItemsUpdated({ datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {

		this.purgeExpiredTemplateModifications(); // When we load new items, purge > 30 seconds old template modifications

		const l = this.list[datastore];
		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", datastore, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;
			if (datastore !== "pastProgramsNeedingAttention" && datastore !== "pastProgramsMatchingSearch") {
				this.isLoading = false;
			}
			if (datastore === "tagPrograms") {
				l.items.forEach(item => {
					item.tagItemId = this.idMap[item.id];
				});
			}
			if (datastore === "channelGroups" && !items?.length) {
				this.useChannelGroupsFallback = true;
			}
		}
	}

	/* Item */
	onFetchItem() {
		this.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.isLoading = false;

		if (entity === "tag") {
			this.idMap = {};
			model.items.forEach(item => this.idMap[item.referenceId] = item.id);
		}
	}

	// TODO: Remove datastore, we're using entities
	// TODO!: Keep item index when rollbacking
	onItemSaved({ datastore, entity, model, targetStore = null }) {
		if (datastore) {
			console.warn("DATASTORE PROPERTY DEPRECATED: %s. Please use ENTITY: %s instead.", datastore, entity);
		}
		const store = targetStore || `${entity}s`;

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.isLoading = false;
	}

	onRemoveItem({ entity, id }) {
		this.isLoading = true;

		const item = this.getItem(entity, id);
		item._isHidden = true;
	}
	onItemRemoved({ entity, id }) {
		this.isLoading = false;

		this.removeItem(entity, id);
	}
	onRollbackRemoveItem({ entity, id }) {
		this.isLoading = false;

		const item = this.getItem(entity, id);
		delete item._isHidden;
	}

	/* Item entity */
	onAddItemEntity({ entities, payload }) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		store.push(payload.data);

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onRemoveItemEntity({ entities, payload }) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		const index = store.findIndex(e => e.id === payload.data.id);
		if (index >= 0) {
			store.splice(index, 1);
		}

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onSetItemEntityProperty() {
		this.isLoading = true;
	}

	// onViewChange({isTranslationList}) {
	// 	console.log("VC! Trans: %s", isTranslationList);
	// 	// debugger;
	// 	this.filters.searchText = "";
	// 	if(!Object.keys(this.filters.filter).length) {
	// 		this.filters.filter = this._prevFilter;

	// 	}

	// 	this.filters.filter.filter = "";
	// 	this.filters.hideWithParent = "";
	// 	this.setOldButActiveFiltering(this.filters.filter);

	// 	// this.initStore("pastProgramsNeedingAttention");
	// }

	onUnmount() {
		this.item = {
			program: {},
			family: {},
		};

		this.initStore("families");
		this.initStore("pastProgramsNeedingAttention");
		this.initStore("pastProgramsMatchingSearch");

		this.list.seriesSeasonPrograms.items = this.initSeriesSeasonPrograms();
		this.list.seriesSeasonPrograms.filters._selectedSeasonId = null;
		this.list.seriesSeasonPrograms.filters.approval = "";

		this.isLoading = false;
	}

	// CUSTOM
	onFilterSeriesSeasons(value) {
		this.list.seriesSeasonPrograms.filters.approval = value;
	}
	onFetchSeriesSeasons(loaders) {
		this.isLoadingNumber = loaders;
		this.list.seriesSeasonPrograms.items = this.initSeriesSeasonPrograms();
	}

	onSeriesSeasonDataLoaded(model) {

		this.purgeExpiredTemplateModifications(); // When we load new seriesSeasons, purge > 30 seconds old template modifications

		this.isLoadingNumber--;
		const { items = [] } = model;

		const loadedType = items.length ? items[0].type : model.type;

		// Multiple program result
		if (model && model.items) {
			this.list.seriesSeasonPrograms.items = this.list.seriesSeasonPrograms.items.filter(i => i.type !== loadedType).concat(model.items);
		}
		// Single program result
		else {
			const index = this.list.seriesSeasonPrograms.items.findIndex(i => i.type === loadedType);

			if (index >= 0) {
				this.list.seriesSeasonPrograms.items.splice(index, 1, model);
			}
			else {
				this.list.seriesSeasonPrograms.items.push(model)
			}
		}

		// HACK: Remove the final loader if we have no series
		if (model.id === -100) {
			this.isLoadingNumber--;
		}
	}

	onFetchSeasonEpisodes(loaders) {
		this.isLoadingNumber = loaders;
		this.list.seriesSeasonPrograms.items = this.list.seriesSeasonPrograms.items
			.filter(i => !["Episode", "Template"].includes(i.type))
			.concat([{ id: -3, type: "Episode", versions: [{ id: 1, title: "" }] }]);
	}

	onSetSelectedSeason(id) {
		this.list.seriesSeasonPrograms.filters._selectedSeasonId = id;
	}

	onConnectSeasonToSeries({ seriesData }) {
		this.isLoadingNumber = 2;
		this.list.seriesSeasonPrograms.items = [seriesData].concat(this.list.seriesSeasonPrograms.items.filter(i => i.id !== -100)); // Preemtively add the connected series
	}

	onDisconnectSeasonFromSeries(seasonId) {
		this.isLoadingNumber = 1;

		// Keep Series, Episodes and just the season I'm looking at
		this.list.seriesSeasonPrograms.items = this.list.seriesSeasonPrograms.items.filter(i => (i.type !== "Season" || i.id === seasonId));
	}

	onSeriesConnectedToSeason() {
		this.isLoadingNumber--;
	}

	onNoSeasonsAvailable() {
		this.isLoadingNumber--;
		this.isLoadingNumber--;
		this.list.seriesSeasonPrograms.items = this.list.seriesSeasonPrograms.items.filter(i => (i.type !== "Season" && i.type !== "Episode"));
	}

	onFetchMasterTemplate(loaders) {
		this.isLoadingNumber += loaders;
	}

	onMasterTemplateDataLoaded(model) {
		this.isLoadingNumber--;

		this.list.seriesSeasonPrograms.items.push({
			...model,
			type: "Template",
		});
	}

	setProgramClassAndTypes(type) {
		this.filters.sportsMetadataIsUsed = false;

		switch (type) {
			case "movies":
				this.filters.programClasses = "regular,children";
				this.filters.programTypes = "single";
				break;
			case "episodes":
				this.filters.programClasses = "regular,children";
				this.filters.programTypes = "episode";
				break;
			case "series":
				this.filters.programClasses = "regular,children";
				this.filters.programTypes = appConfig.features.metadataSeriesAndEpisodesSeparateFilters ? "series" : "episode";
				break;
			case "seasons":
				this.filters.programClasses = "regular,children";
				this.filters.programTypes = "season";
				break;
			case "sport":
				this.filters.programClasses = "sport";
				this.filters.programTypes = "single,episode";
				break;
			case "sportseries":
				this.filters.programClasses = "sport";
				this.filters.programTypes = "series";
				break;
			case "_eventplanner":
				this.filters.programClasses = "sport";
				this.filters.programTypes = "single,episode";
				this.filters.sportsMetadataIsUsed = true;
				break;
			default:
				this.filters.programClasses = "";
				this.filters.programTypes = appConfig.features.metadataVodHubProgramListFilter
					? "single,season,series,episode"
					: "single,episode";

				if (this.filters.searchText.length) {
					this.filters.programTypes = "single,season,series";
				}
				break;
		}
	}

	setOldButActiveFiltering(filterObj, payload) {
		const { filter } = filterObj || {};

		if (filter === "HasActiveRights" && !(payload && payload.premiere)) {
			this.filters.filter.premiere = "";
			this.filters.filter.orderBy = "premiereDescending";
		}
		else {
			this.filters.filter.filter = "";
			this.filters.filter.orderBy = payload?._searchPastPrograms ? "title" : "premiere";
		}
	}

	setOldSearchFiltering() {
		if (!appConfig.features.metadataNewDefaultSearchBehaviour) {
			return;
		}

		if (this.filters.searchText?.length && !this.filters.filter._searchPastPrograms) {
			this.filters.filter.premiere = "";
			this.filters.filter.filter = "HasActiveOrUpcomingRights";
		} else if (this.filters.searchText?.length && this.filters.filter._searchPastPrograms) {
			this.filters.filter.filter = "";
		}
	}

	selectItem({ dataStore, item }) {
		const maxSelectedItems = 2;
		let index = this.selected[dataStore].indexOf(item);
		if (index >= 0) {
			this.selected[dataStore].splice(index, 1);
		} else if (this.selected[dataStore].length < maxSelectedItems) {
			this.selected[dataStore].push(item);
		}
	}

	clearSelected(dataStore) {
		this.selected[dataStore] = [];
	}

	// EXTERNAL COMPONENT ACTIONS
	onEditorSaved(payload) {
		if (payload && payload.datastore) {
			console.warn("Payload uses deprecated datastore property: %s", payload.datastore);
		}
		switch (payload.entity) {
			case "family":
				const familyIndex = this.list.families.items.findIndex(f => f.id === payload.model.id);
				if (familyIndex >= 0) {
					this.list.families.items.splice(familyIndex, 1, payload.model);
				} else {
					this.list.families.items.push(payload.model);
				}
				break;
			case "program":
				this.itemUpdatedTransforms(payload);

				// When creating a new series from the season view, we need to connect it to the season aswell.
				const { model } = payload;

				const l = this.list.seriesSeasonPrograms;
				if (l.filters._selectedSeasonId && l.items.length && l.items.filter(i => i.id === model.id).length === 0 && model.type === "Series") {
					this.connectSeriesToSeason(payload, l.filters._selectedSeasonId);
				}
				break;
			case "item": // item = Tags item
				this.syncTagsApproval(payload);
				break;
		}
	}

	// Sync STAR container approval with metadata
	onStarContainerApproved({ container, isApproved, targetStore }) {
		if (container && container.programGuid) {
			Actions.updateItem.defer("program", { id: container.programGuid }, {
				// TODO: Get the approval object directly from the STAR-container when STAR has the same approve format.
				// See: https://3.basecamp.com/3091592/buckets/3735615/todos/650976146
				imageApproval: {
					status: isApproved ? "approved" : "unapproved",
					by: "STAR-editor", // TODO: Get this from the UserStore if STAR can't provide it?
					on: Moment().toISOString(),
				}
			}, "patch", targetStore);
		}
	}

	// Since metadata knows nothing about images we have to refresh them manually when
	// added or removed in the STAR container editor
	onStarContainerMainUpdated({ programGuid, assets }) {
		if (programGuid) {
			const primaryAssets = assets.filter(a => a.category === "Main");
			const assetGuid = primaryAssets.length && primaryAssets[0].assetGuid || "00000000-1000-1000-8000-000000000000"; // We "remove" the image by setting the image src to an unused GUID
			swapImageAsset(programGuid, assetGuid);
		}
	}

	// Update family member information when program is added to family through dialog
	onFamilyMemberAdded({ familyId, programId, name }) {
		const program = this.list.programs.items.find(program => program.id === programId);
		if (program) {
			if (!program.links)
				program.links = [];

			program.links.push({
				programId: familyId,
				name: name,
			});
		}

		this.dialogOpen = false;
	}

	onRatingsEditorSaved(payload) {
		["programs", "tagPrograms", "translations"].forEach(list => {
			const program = this.list[list].items.find(p => p.id === payload.id);
			if (program) {
				const programFinnishVersion = program.versions && program.versions.find(v => v.versionId === 4);
				const payloadFinnishVersion = payload.versions && payload.versions.find(v => v.versionId === 4);
				if (programFinnishVersion && payloadFinnishVersion) {
					programFinnishVersion.finnishRatingApproval = payloadFinnishVersion.finnishRatingApproval;
				}
			}
		});
	}

	onFetchChannelGroups() {
		this.onFetchItems({ store: "channelGroups" });
	}

	onUseChannelGroupsFallback() {
		this.useChannelGroupsFallback = true;
		this.onItemsUpdated({ datastore: "channelGroups", items: [], appendToExistingItems: false, numberOfItems: 0 });
	}

	// HELPERS
	// Purges > 30 seconds old template modifications since they should have
	// been completed by the API job by now (it runs every 10 seconds)
	purgeExpiredTemplateModifications() {
		const mods = this.masterTemplateModifications.entries();
		for (let [id, mod] of mods) {
			if (new Date().getTime() - mod.time.getTime() > 30 * 1000) {
				this.masterTemplateModifications.delete(id);
			}
		}
	}

	getItem(entity, id) {
		const store = this.list[`${entity}s`].items;
		return store.find(e => e.id === id);
	}

	removeItem(entity, id) {
		const store = this.list[`${entity}s`].items;
		const index = store.findIndex(e => e.id === id);
		store.splice(index, 1);
	}

	persistToStore(store, model) {
		const storeItems = this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(m => (m.id === model.id));

		if (index >= 0) {
			storeItems[index] = model;
		} else {
			storeItems.push(model);
		}

		return true;
	}

	updateStore(store, model) {
		const storeItems = this.list[store] ? this.list[store].items : this[store];

		if (!storeItems) {
			return false;
		}

		let didUpdate = false;
		storeItems.forEach((item, index) => {
			if (item.id === model.id) {

				storeItems[index] = {
					...model,
					type: item.type, // Never change the item type (since it could be a template)
				};

				// Never update rights in the list with the rights that we get from an updated program
				// since that program can contain more rights that should not be listed
				if (store === "programs") {
					storeItems[index].rights = item.rights;
				}

				didUpdate = true;
			}
		});

		return didUpdate;
	}

	initStore(store, filters = null, items = []) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			filters,
		};
	}

	initSeriesSeasonPrograms() {
		return [
			{ id: -1, type: "Series", versions: [{ id: 1, title: "" }], originalTitle: "" },
			{ id: -2, type: "Season", versions: [{ id: 1, title: "" }] },
			{ id: -3, type: "Episode", versions: [{ id: 1, title: "" }] },
		];
	}

	itemUpdatedTransforms({ entity, model, originalId, targetStore = null }) {
		const store = `${entity}s`;
		const newModel = { ...model };

		// Update the list item with the fresh editor model
		this.onItemSaved({ entity, model: newModel, originalId, targetStore });

		// Also update the main program list if the program is visible there
		if (targetStore && store !== targetStore) {
			this.updateStore(store, newModel);
		}

		// Also update the program item store (HACK) if the model id matches
		if (this.item.program.id === newModel.id) {
			this.item.program = newModel;
		}

		// Also update the linear schedule programs
		if (this.list.schedules.items.length) {
			this.list.schedules.items = this.list.schedules.items.map(channel => {
				const { broadcasts, ...rest } = channel;
				return {
					...rest,
					broadcasts: broadcasts.map(({ program, ...rest }) => ({
						...rest,
						program: program.id === newModel.id ? newModel : program
					})),
				}
			});
		}

		// If this is a template, also update all programs with the same masterId
		if (newModel.isMasterTemplate) {
			this.updateSlavePrograms(store, newModel);
			this.updateSlavePrograms("seriesSeasonPrograms", newModel);
		}
	}

	updateSlavePrograms(store, newModel) {
		const storeItems = this.list[store] ? this.list[store].items : this[store];

		if (!storeItems) {
			return false;
		}

		storeItems
			.filter(i => i.masterId && i.masterId === newModel.id)
			.forEach(i => {
				const templatedModel = {
					...newModel,

					id: i.id,
					guid: i.guid,
					isMasterTemplate: i.isMasterTemplate,
					masterId: i.masterId,
					name: i.name,
					originalTitle: i.originalTitle,
					displayName: i.displayName,
					sortName: i.sortName,
					episodeNumber: i.episodeNumber,
					rights: i.rights,
				};

				this.updateStore(store, templatedModel);
			});
	}

	// Sync Discovery Tags approval with metadata
	syncTagsApproval({ model }) {
		const { referenceId, approval } = model;
		const programId = parseInt(referenceId);
		if (!programId) {
			return;
		}

		Actions.updateItem.defer("program", { id: programId }, {
			tagsApproval: approval,
		}, "patch");

		["families", "tagPrograms", "seriesSeasonPrograms"].forEach(list => {
			const program = this.list[list].items.find(p => p.id === programId);
			if (program) {
				program.tagsApproval = model.approval;
			}
		});
	}

	removeTagProgram(id) {
		const index = this.list.tagPrograms.items.findIndex(program => program.id === id);
		this.list.tagPrograms.items.splice(index, 1);
	}

	onFetchTagPrograms(clear) {
		if (clear) {
			this.filters.filter.filter = "";
			this.initStore("tagPrograms");
		}
		this.isLoading = true;
	}

	// Connect saved series programs with the current season
	connectSeriesToSeason({ model }, seasonId) {
		Actions.connectSeasonToSeries.defer(seasonId, model);
	}

	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(prevFilterStorageKey, JSON.stringify(this._prevFilter));
		sessionStorage.setItem(schedulesFilterStorageKey, JSON.stringify(this.list.schedules.filters));
	}

}
export default alt.createStore(MetadataStore, '[Metadata]Store');

function getDefaultChannelGroup() {
	if (appConfig.features.metadataLinearDefaultChannelGroup) {
		return appConfig.features.metadataLinearDefaultChannelGroup;
	}

	if (!appConfig.features.hideTV4Channels) {
		return "tv4";
	}
	if (!appConfig.features.hideCMoreChannels) {
		return "cmore";
	}
	if (!appConfig.features.hideMTVChannels) {
		return "mtv";
	}

	return "";
}