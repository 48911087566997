import React 				 from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import Module        		 from '../app'

import Library       		 from './library/app'
import Season        		 from './season/app'

import Schedule        		 from './linear/app'

import Tags 		 		 from './tags/app'
import TagPrograms			 from './tags/programs/app'

import Families 			 from './families/app'
import Family				 from './family/app'

import GenericEditor 		 from './genericeditor/app'
import LocaleEditor  		 from './localeeditor/app'
import ProgramTagsEditor 	 from './programtagseditor/app'
import TagEditor 			 from './tageditor/app'
import FamilyEditor			 from './familyeditor/app'
import RatingsEditor		 from './ratingseditor/app'

import StarContainer 		 from '../star2/containereditor-metadata/app'
// import TranslateEditor  from './localeeditor/app'

import EventPlannerSchedule from '../eventplanner/schedule/app'
import { fetchCatalogues } from '../../apis/metadata'

import appConfig from 'config'

export default class MetadataModule {
	static catalogues = [];
	static catalogueMap = {};
	static setupPromises = [];
	static initialSetup() {
		if (!this.setupPromises.length) {
			this.setupPromises = [
				new Promise((resolve) => {
					if (appConfig.features.metadataUseCatalogues) {
						// TODO: This will fail silently (and call resolve) if the user doesn't have access to the metadata module.
						// 		 We should check if the user has access before fetching the catalogues. OR maybe turn off API security for the /catalogues endpoint?
						fetchCatalogues().then(
							(response) => {
								this.catalogues.push(...response.items);
								this.catalogues.forEach(catalogue => {
									try {
										if (typeof catalogue.uiData === "string") {
											catalogue.uiData = JSON.parse(catalogue.uiData);
										} else {
											catalogue.uiData = catalogue.uiData ?? {};
										}
									} catch {
										catalogue.uiData = {};
										console.error("Failed to parse uiData for catalogue", catalogue);
									}
									this.catalogueMap[catalogue.name] = catalogue;
								});
								resolve();
							},
							resolve,
						);
					} else {
						this.catalogues.push(...(appConfig.features.metadataCatalogues ?? []));
						this.catalogues.forEach(catalogue => {
							this.catalogueMap[catalogue.name] = catalogue;
						});
						resolve();
					}
				}),
			];
		}

		return Promise.all(this.setupPromises);
	}

	static navOptions() {
		if (this.catalogues.length) {
			return [
				...this.catalogues.map(catalogue => ({
					displayName: catalogue.displayName,
					url: `metadata/programs/${catalogue.name}`,
					shieldKey: `metadata.library-${catalogue.name}`
				})).sort((a, b) => {
					if (a.name === "all") return -1;
					if (b.name === "all") return 1;
					return a.displayName.localeCompare(b.displayName);
				}),
			];
		}

		return [
			{
				displayName: "Loading...",
				shieldKey: "metadata",
			}
		];
	}

	static seasonRoutes() {
		return (
			<>
				<Route path="season/:seasonId/episode/:episodeId" component={Season} />
				<Route path="season/(:seasonId)" component={Season} />
				<Route path="series/(:seriesId)" component={Season} />
				<Route path=":programId" component={Season} />
			</>
		);
	}

	static routes() {
		return (
			<Route path="metadata" component={Module} module="metadata">

				<Route path="images" app="images" modal={true} wideModal={true} returnTo="/metadata/programs">
					<Route path="program/:id(/:catalogue)" component={StarContainer} access="reader" modal={true} />
					<Route path="program/guid/:reference(/:catalogue)" component={StarContainer} access="reader" modal={true} />
				</Route>

				<Route path="programs" app="library" returnHere={({ catalogue }) => `/metadata/programs${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />

					<Route path="create(/:type)(/:catalogue)" component={GenericEditor} access="editor" modal={true} />
					<Route path=":catalogue">
						<IndexRoute component={Library} />
						{this.seasonRoutes()}
					</Route>

					<Route path="(:id)/edit(/:catalogue)" component={GenericEditor} roles={{ generic: "editor" }} modal={true} />
					<Route path="(:id)/locale/:versionId/edit(/:catalogue)" component={LocaleEditor} roles={getLocaleRoleAccess()} modal={true} /*wideModal={isWideModal}*/ />

					<Route path="(:id)/tags/edit(/:catalogue)" component={ProgramTagsEditor} access="reader" modal={true} />
					<Route path="(:id)/agerating/edit" component={RatingsEditor} access="reader" modal={true} wideModal={true} />

					{this.seasonRoutes()}
				</Route>

				<Route path="linear">
					<IndexRoute component={Schedule} />
					<Route path="today(/:channels)" component={Schedule} />
					<Route path=":date(/:channels)" component={Schedule} />
				</Route>

				<Route path="linear-epg">
					<IndexRoute component={Schedule} />
					<Route path="today(/:channels)" component={Schedule} />
					<Route path=":date(/:channels)" component={Schedule} />
				</Route>

				<Route path="linear-catchup">
					<IndexRoute component={Schedule} />
					<Route path="today(/:channels)" component={Schedule} />
					<Route path=":date(/:channels)" component={Schedule} />
				</Route>

				<Route path="schedule">
					<IndexRoute component={EventPlannerSchedule} />
				</Route>
				<Route path="schedule-catchup">
					<IndexRoute component={EventPlannerSchedule} />
				</Route>

				<Route path="translation(/:catalogue)" app="translation" returnHere={({ catalogue }) => `/metadata/translation${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />
					{MetadataModule.seasonRoutes()}
				</Route>
				<Route path="translation-swedish(/:catalogue)" languageFilter="Swedish" app="translation" returnHere={({ catalogue }) => `/metadata/translation${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />
					{MetadataModule.seasonRoutes()}
				</Route>
				<Route path="translation-norwegian(/:catalogue)" languageFilter="Norwegian" app="translation" returnHere={({ catalogue }) => `/metadata/translation${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />
					{MetadataModule.seasonRoutes()}
				</Route>
				<Route path="translation-danish(/:catalogue)" languageFilter="Danish" app="translation" returnHere={({ catalogue }) => `/metadata/translation${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />
					{MetadataModule.seasonRoutes()}
				</Route>
				<Route path="translation-finnish(/:catalogue)" languageFilter="Finnish" app="translation" returnHere={({ catalogue }) => `/metadata/translation${catalogue ? `/${catalogue}` : ""}`}>
					<IndexRoute component={Library} />
					{MetadataModule.seasonRoutes()}
				</Route>

				<Route path="tags" app="tags" returnHere>
					<IndexRoute component={Tags} />

					<Route path="create" component={TagEditor} access="editor" modal={true} />
					<Route path="(:id)/edit" component={TagEditor} access="reader" modal={true} />

					<Route path="(:id)/programs" component={TagPrograms} />
				</Route>

				<Route path="families" app="families">
					<IndexRoute component={Families} />

					<Route path="create" component={FamilyEditor} access="editor" modal={true} returnTo="/metadata/families" />
					<Route path="(:id)/edit" component={FamilyEditor} access="editor" modal={true} returnTo="/metadata/families" />
					{/*<Route path="(:id)/locale/(:versionId)/edit" component={FamilyEditor} access="editor" modal={true} returnTo="/metadata/families" />*/}

					<Route path="(:id)" component={Family} />
				</Route>
			</Route>
		);
	}
}

function getLocaleRoleAccess() {
	return {
		1: { swedish: "editor" },
		2: { norwegian: "editor" },
		3: { danish: "editor" },
		4: { finnish: "editor" },
	}
}
