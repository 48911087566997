import React from 'react'
import PropTypes from 'prop-types'

import * as VJSUtils from './utils'

import './vjs.css'

export default class VideoJSPlayer extends React.Component {
	playerIndex = null;
	playerRef = React.createRef();
	isInitialized = false;
	state = {
		playerLoaded: false,
	};

	async componentDidMount() {
		this.handleSources(this.props.sources, this.props.asset);
	}

	componentDidUpdate() {
		this.handleSources(this.props.sources, this.props.asset);
	}

	componentWillUnmount() {
		VJSUtils.disposePlayer(this.playerIndex);
	}

	handleSources = (sources, asset) => {
		if (this.isInitialized) {
			return;
		}
		
		const source = sources?.[0];
		const isSingu = asset?.files?.find(f => f?.fileStorageName === "mtvproxies-singularity");
		if (source?.src && isSingu) {
			this.initSingu(source);
			this.isInitialized = true;
		} else if (source?.src) {
			this.init(source);
			this.isInitialized = true;
		}
	}

	initSingu = (source) => {
		const manifestUrl = source.src;
        const manifestURLParsed = new URL(manifestUrl);
        const licenseServer = "https://origin.prod.singu.fi";
        const playKey = manifestURLParsed.searchParams.get("play_key")
		this.init({
			src: manifestUrl,
			type: "application/dash+xml",
			keySystems: {
				"com.widevine.alpha": `${licenseServer}/drm/widevine?key=${playKey}`,
				"com.microsoft.playready": `${licenseServer}/drm/playready?key=${playKey}`,
			},
		});
	}

	init = async (source) => {
		this.playerIndex = await VJSUtils.initPlayer({
			playerRef: this.playerRef.current,
			source: source,
			asset: this.props.asset,
		});
	}

	render() {
		return (
			<div className={`c6-vjs ${this.props.sources?.length ? "has-sources" : ""}`}>
				<video className="video-js" ref={this.playerRef} />
			</div>
		);
	}
}


VideoJSPlayer.propTypes = {
	asset: PropTypes.shape({
		assetGuid: PropTypes.string,
	}),
	sources: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string,
	})),
};
