import videojs from 'video.js'
import 'videojs-contrib-eme' // EME plugin for DRM (Widevine, PlayReady, FairPlay)
import 'video.js/dist/video-js.min.css'

let players = [];

export async function initPlayer({ playerRef, options = {}, source }) {
	const player = videojs(playerRef, {
		autoplay: options.autoplay ?? false,
		controls: true,
		width: "100%",
		height: "100%",
	}, function() {
		player.eme();
		player.src(source);
	});

	players.push(player);
	const playerIndex = players.length - 1;
	return playerIndex;
}

export async function disposePlayer(playerId) {
	const player = players[playerId];
	if (player?.dispose) {
		player.dispose();
	}
}
