import { get } from '../core/c6'

const API_EPG = "internalepg";
const API_METADATA = "internalmetadata";
const API_VOD = "internalvod";

export const fetchEpgSchedules          = filters               => get(API_EPG, "schedules", filters);
export const fetchEpgBroadcasts         = filters               => get(API_EPG, "broadcasts", filters);

export const fetchMetadataProgram       = (id, filters)         => get(API_METADATA, `programs/${id}`, filters);
export const fetchMetadataSeasons       = (seriesId, filters)   => get(API_METADATA, `programs/${seriesId}/seasons`, filters);
export const fetchMetadataEpisodes      = (seasonId, filters)   => get(API_METADATA, `programs/${seasonId}/episodes`, filters);
export const fetchMetadataFamily        = (id, filters)         => get(API_METADATA, `families/${id}`, filters);
export const search                     = filters               => get(API_METADATA, "search", filters);

export const fetchPackages              = filters               => get(API_VOD, "packages", filters);
export const fetchPackageSchedules      = filters               => get(API_VOD, "packages/premiere", filters);
export const fetchPackageSchedulesExcel = filters               => get(API_VOD, "excel", filters);

/* **************** Generic ***************** */
//export const fetchUrl = url => get(API, url);