import React from 'react'
import Broadcasts from './broadcasts'

import Button from '../../../components/ui/controls/button'
import Actions from '../actions'

import appConfig from 'config'
// import channelLogos from '../shared/channels.svg'
// import channelLogos from '!!file?name=channels.svg!../shared/channels.svg'

export default ({
	channel: channelBroadcasts,
	renderChannelName = false,
	channelDisplayName,
	group,
	noBroadcastsText = "Inga visningar.",
	copyProgramInfoButtonText,
	copyScheduleForDay,
	children,
	excelDownloadSites,
	vodType,
	getChannelHeaderExtra = null,
	day,
 }) => {
	const { channel, broadcasts } = channelBroadcasts;
	const { name, displayName } = channel;
	let lastAvailableDayTitle = "";
	let isVodViews = false;

	if (channel.name.includes("tv4play")) {
		lastAvailableDayTitle = "Sista chansen";
		isVodViews = true;
	}

	return (
		<div className="c6-schedule-channel">
			<h1 className={`channel-logo channel-${name?.toLowerCase()}`}>
				{renderChannelName && (channelDisplayName ?? displayName ?? name)}
				<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
					{isVodViews && (
						<Button
							type="file-excel"
							noBackground
							title=""
							hoverTitle="Ladda ned kommande fem veckor som Excel"
							onClick={() => handleExportButtonClick(name, excelDownloadSites, vodType)} 
						/>
					)}
					{copyScheduleForDay && copyProgramInfoButtonText && (
						<Button
							type="content_copy"
							noBackground
							title={copyProgramInfoButtonText}
							onClick={copyScheduleForDay.bind(this, day, channel, broadcasts)} 
						/>
					)}
					{getChannelHeaderExtra ? getChannelHeaderExtra(channel) : null}
				</div>
			</h1>
			
			{lastAvailableDayTitle && broadcasts?.length > 0 && <h5 className="vod-package-last-day">{lastAvailableDayTitle}</h5>}
			{broadcasts?.length
				? <Broadcasts broadcasts={broadcasts} group={group}>{children}</Broadcasts>
				: <p className="no-broadcasts">{noBroadcastsText}</p>
			}
		</div>
	);
};

//<h1 className={`channel-logo channel-${channel.channel.name}`}>
// <svg height="25"><use xlinkHref={`/channels.svg#${channel.channel.name}`}></use></svg>

{/* <h1>
			<svg><use width="50" height="50" href={`/channels.svg#${channel.channel.name}`}></use></svg> */}
function handleExportButtonClick(channelName, sites, type) {
	const isTv4Play = channelName.includes("tv4play");
	Actions.exportVodPackageExcel({
		sites,
		type,
		apikey: appConfig.api.contentKey || "",
	}, isTv4Play);
}
