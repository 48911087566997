import React, { Component } from 'react'
import { Link } from 'react-router'
import moment from 'moment-timezone'

import LazyLoad from '../../../components/lazyload'

import { getItemHref } from '../shared/utils'
import Widget from './widget'

import appConfig from 'config'

import './itemList.css'

const ItemList = ({ widgetData = {}, compact = true }) => {
	const { items: programs } = widgetData;

	return (
		<div className="c6-press-itemlist">
			{programs && programs.map(p => <Item key={p.id} {...p} compact={compact} />)}
		</div>
	);
}

export default Widget(ItemList);

const Item = (props) => {
	const { heading, description, start, positionDate, positionText, category, reference, assets: itemAssets = [], program = {}, compact = true } = props;
	const { title, synopsis = {}, assets: programAssets = [], seriesTitle, classification } = program;
	const { short } = synopsis;

	const date = (start || positionDate) && moment(start || positionDate).tz(appConfig.features.pressTimezone);
	const href = getItemHref(props);
	const logo = getLogo(positionText);

	const mainImage = itemAssets.find(a => a.type === "image") || programAssets.find(a => a.type === "image"); 
	const { src, srcSet } = getSources(mainImage, compact);

	const subTitle = seriesTitle || description || short;
	let mainTitle = "";
	if (heading === subTitle && title?.length) {
		mainTitle = title;
	}  else if (heading) {
		mainTitle = heading;
	} else {
		mainTitle = program?.originalTitle;
	}
	return (
		<Link className={`c6-press-item ${compact ? "compact" : ""}`} to={href}>
			<div className="image">
				{mainImage && (
					// Decided not to use ResponsiveImage here because the sizes there don't work with the layout used here.
					// Maybe we should add a new "format" to ResponsiveImage that does the same as below?
					<LazyLoad offsetVertical={600}>
						<img
							alt={heading}
							src={src}
							srcSet={srcSet}
							sizes={`(max-width: 700px) 100px, ${compact ? "100px" : "200px"}`}
							decoding="async"
						/>
					</LazyLoad>
				)}
			</div>
			<div className="info">
				{(date || logo) && (
					<div className="info-header">
						<h3>
							{date && classification === "Sport" && <span>{date.format(`HH:mm ${appConfig.features.pressDateMonthFormat || "D/M"}`)}</span>}
							{/* {date && classification !== "Sport" && <span>{date.format("D/M")}</span>} */}
							{date && classification !== "Sport" && <span>{getPremiereDate(date)}</span>}
						</h3>
						{logo && <span className={`channel-logo channel-${logo}`}></span>}
					</div>
				)}
				<h2>{mainTitle}</h2>
				{compact ? null : <p>{subTitle}</p>}
			</div>
		</Link>
	);
}

function getPremiereDate(startDate) {
	// We only want to show the date if it's a future date or a premiere
	const nowDate = moment();
	if (startDate.isAfter(nowDate)) {
		return startDate.format(appConfig.features.pressDateMonthFormat || "D/M");
	}

	return null;
}

function getSources(image, compact) {
	if (image && image.id) {
		const srcFormat = compact ? "Comet_16x9_200x112" : "Comet_16x9_400x224"; 
		const imgUrlStart = appConfig.media.star;

		/* TODO: Use these URLS when the images in Azure blob storage works */ 
		// return {
		// 	src: `${imgUrlStart}${srcFormat}/${image.id}.jpg`,
		// 	srcSet: `${imgUrlStart}Comet_16x9_200x112/${image.id}.jpg 200w, ${imgUrlStart}Comet_16x9_400x224/${image.id}.jpg 400w`,
		// }

		return {
			src: `${imgUrlStart}${image.id}/${srcFormat}.jpg`,
			srcSet: `${imgUrlStart}${image.id}/Comet_16x9_200x112.jpg 200w, ${imgUrlStart}${image.id}/Comet_16x9_400x224.jpg 400w`,
		}
	}

	return {};
}

function getLogo(positionText) {
	if (positionText?.toLowerCase() === "mtvfi" || positionText?.toLowerCase().includes("cmore")) {
		return "mtv-katsomossa";
	}

	return positionText;
}