import MetadataModule from '../apps/metadata/module'
import { hasAccessToPath, hasRole } from './services/auth'

import appConfig from 'config'

export function getModuleStructure(moduleStructure = fullModuleStructure()) {
	return moduleStructure.map(module => {
		const filteredChildren = module.children?.filter(m => hasModuleAccess(m));
		return {
			...module,
			children: filteredChildren ? getModuleStructure(filteredChildren) : null,
		};
	}).filter(module => module.children?.length !== 0 || module.url === "/");
}

export function hasModuleAccess(module) {
	if (module.shieldKey) {
		const [modulePart, appPart] = module.shieldKey.split(".");
		const hasAppAccess = hasAccessToPath([{ module: modulePart, app: appPart }]);
		let hasRoleAccess = true;
		if (module.shieldRole) {
            // Use hasRole instead of "roles" argument in hasAccessToPath since that one is less strict
			hasRoleAccess = hasRole(module.shieldRole);
		}
		return hasAppAccess && hasRoleAccess;
	}
	return true;
}

export function waitForModules() {
	return new Promise((resolve) => {
		Promise.all([
			MetadataModule.initialSetup(),
		]).then(() => {
			resolve();
		});
	});
}

// TODO: Get all modules from Shield and check if they are defined at all.
// If not, remove them from the module structure.
export function fullModuleStructure() {
	if (appConfig.api.shield?.includes("vodhub")) {
		return [
			{
				key: "vod",
				displayName: "VOD",
				icon: "play_circle_filled",
				children: [
					{
						key: "group1",
						children: [
							{
								displayName: "Plan",
								url: "planner/calendar",
								shieldKey: "planner.calendar",
							},
							{
								displayName: "Programs",
								url: "planner/programs",
								shieldKey: "planner.programs",
								hideFromDashboardIfNoAccess: true,
							},
							{
								displayName: "Traffic",
								url: "traffic/programs",
								shieldKey: "traffic.programs",
							},
						],
					},
					{
						displayName: "API services",
						url: "vod/packages-new",
						shieldKey: "vod.packages-new",
					},
					{
						displayName: "Rights",
						children: [
							{
								displayName: "Contracts",
								url: "acq/contracts-vod",
								shieldKey: "acq.contracts",
							},
							{
								displayName: "Licenses",
								url: "acq/licenses-vod",
								shieldKey: "acq.licenses",
							}
						]
					},
					{
						displayName: "Admin",
						url: "vod/admin",
						shieldKey: "vod.admin",
						hideFromDashboardIfNoAccess: true,
					},
				],
			},
			{
				key: "metadata",
				displayName: "Metadata",
				icon: "movie",
				children: [
					{
						displayName: "Catalogues",
						children: [
							...MetadataModule.navOptions(),
						]
					},
					{
						displayName: "Translations",
						children: [
							{
								displayName: "Swedish",
								url: "metadata/translation-swedish",
								shieldKey: "metadata.translation",
								shieldRole: "metadata.swedish",
							},
							{
								displayName: "Norwegian",
								url: "metadata/translation-norwegian",
								shieldKey: "metadata.translation",
								shieldRole: "metadata.norwegian",
							},
							{
								displayName: "Danish",
								url: "metadata/translation-danish",
								shieldKey: "metadata.translation",
								shieldRole: "metadata.danish",
							},
							{
								displayName: "Finnish",
								url: "metadata/translation-finnish",
								shieldKey: "metadata.translation",
								shieldRole: "metadata.finnish",
							},
						]
					}
				],
			},
			{
				key: "star",
				displayName: "Star MAM",
				icon: "star",
				children: [
					{
						key: "group1",
						children: [
							{
								displayName: "Assets",
								url: "star/search",
								shieldKey: "star.assets",
							},
							{
								displayName: "Albums",
								url: "star/albums",
								shieldKey: "star.albums",
							},
							{
								displayName: "Trashcan",
								url: "star/trashcan",
								shieldKey: "star.trashcan",
							},
							{
								displayName: "Admin",
								url: "star/admin",
								shieldKey: "star.admin",
							},
						],
					},
				],
			},
			{
				key: "ordersadmin",
				displayName: "Orders",
				icon: "monetization_on",
				children: [
					{
						displayName: "Orders",
						url: "orders/orders",
						shieldKey: "ordersadmin.orders",
					},
					{
						displayName: "Admin",
						url: "orders/admin",
						shieldKey: "ordersadmin.admin",
					},
				],
			},
			{
				key: "shield",
				displayName: "Shield security",
				icon: "security",
				children: [
					{
						key: "group1",
						children: [
							{
								displayName: "Comet users",
								url: "shield/logins-users",
								shieldKey: "shield.users",
							},
							{
								displayName: "API keys",
								url: "shield/logins-apikeys",
								shieldKey: "shield.users",
							},
							{
								displayName: "Admin",
								url: "shield/admin",
								shieldKey: "shield.modules",
							}
						],
					},
				],
			},
		];
	}

	return [
		{
			key: "vod",
			displayName: "VOD",
			icon: "play_circle_filled",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "Plan",
							url: "planner/calendar",
							shieldKey: "planner.calendar",
						},
						{
							displayName: "Programs",
							url: "planner/programs",
							shieldKey: "planner.programs",
							hideFromDashboardIfNoAccess: true,
						},
						{
							displayName: "Traffic",
							url: "traffic/programs",
							shieldKey: "traffic.programs",
						},
					],
				},
				{
					displayName: "Distribution",
					children: [
						{
							displayName: "API services",
							url: "vod/packages-new",
							shieldKey: "vod.packages-new",
						},
						{
							displayName: "DAI",
							url: "vod/dai",
							shieldKey: "vod.dai",
						},
						{
							displayName: "Kavi",
							url: "vod/kavi",
							shieldKey: "vod.kavi",
						},
					],
				},
				{
					displayName: "Rights",
					children: [
						{
							displayName: "Contracts",
							url: "acq/contracts-vod",
							shieldKey: "acq.contracts",
						},
						{
							displayName: "Licenses",
							url: "acq/licenses-vod",
							shieldKey: "acq.licenses",
						}
					]
				},
				{
					displayName: "Admin",
					url: "vod/admin",
					shieldKey: "vod.admin",
					hideFromDashboardIfNoAccess: true,
				},
			],
		},
		{
			key: "live",
			displayName: "Live",
			icon: "television-classic",
			children: [
				{
					displayName: "Plan",
					children: [
						{
							displayName: "MTV",
							url: "eventplanner/events/mtv",
							shieldKey: "eventplanner.cms-events-mtv",
						},
						{
							displayName: "TV4",
							url: "eventplanner/events/tv4",
							shieldKey: "eventplanner.cms-events",
						}
					]
				},
				{
					displayName: "Metadata",
					children: [
						{
							displayName: "MTV",
							url: "eventplanner/events-metadata/mtv",
							shieldKey: "eventplanner.cms-events-metadata-mtv",
						},
						{
							displayName: "TV4",
							url: "eventplanner/events-metadata/tv4",
							shieldKey: "eventplanner.cms-events-metadata",
						}
					]
				},
				{
					displayName: "Encoder Allocation",
					children: [
						{
							displayName: "MTV",
							url: "eventplanner/resources/mtv",
							shieldKey: "eventplanner.resources-mtv",
						},
						{
							displayName: "TV4",
							url: "eventplanner/resources/tv4",
							shieldKey: "eventplanner.resources-tv4",
						}
					]
				},
				{
					key: "group1",
					children: [
						{
							displayName: "MCR",
							url: "eventplanner/events-mcr",
							shieldKey: "eventplanner.mcr",
						},
						{
							displayName: "Admin",
							url: "eventplanner/admin-live",
							shieldKey: "eventplanner.admin",
						},
					],
				},
			],
		},
		{
			key: "linear",
			displayName: "Linear",
			icon: "view_week1",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "Ingest",
							url: "schedules/changes",
							shieldKey: "schedules.changes",
						},
						{
							displayName: "EPG",
							url: "metadata/linear-epg",
							shieldKey: "metadata.linear",
						},
						{
							displayName: "Traffic",
							url: "schedules/releases",
							shieldKey: "schedules.releases",
						},
					],
				},
				{
					displayName: "Rights",
					children: [
						{
							displayName: "Catchup",
							url: "metadata/schedule-catchup",
							shieldKey: "metadata.schedule",
						},
						{
							displayName: "Contracts",
							url: "acq/contracts-linear",
							shieldKey: "acq.contracts",
						},
						{
							displayName: "Licenses",
							url: "acq/licenses-linear",
							shieldKey: "acq.licenses",
						},
					],
				},
				{
					key: "group2",
					children: [
						{
							displayName: "Admin",
							url: "eventplanner/admin-linear",
							shieldKey: "eventplanner.admin",
						},
					],
				},
			],
		},
		{
			key: "metadata",
			displayName: "Metadata",
			icon: "movie",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "Broadcast texts",
							url: "metadata/schedule",
							shieldKey: "metadata.schedule",
						},
						{
							displayName: "Program families",
							url: "metadata/families",
							shieldKey: "metadata.families",
						},
						{
							displayName: "Tags",
							url: "metadata/tags",
							shieldKey: "metadata.tags",
						},
					]
				},
				{
					displayName: "Catalogues",
					children: [
						...MetadataModule.navOptions(),
					]
				},
				{
					displayName: "Translations",
					children: [
						{
							displayName: "Swedish",
							url: "metadata/translation-swedish",
							shieldKey: "metadata.translation",
							shieldRole: "metadata.swedish",
						},
						{
							displayName: "Norwegian",
							url: "metadata/translation-norwegian",
							shieldKey: "metadata.translation",
							shieldRole: "metadata.norwegian",
						},
						{
							displayName: "Danish",
							url: "metadata/translation-danish",
							shieldKey: "metadata.translation",
							shieldRole: "metadata.danish",
						},
						{
							displayName: "Finnish",
							url: "metadata/translation-finnish",
							shieldKey: "metadata.translation",
							shieldRole: "metadata.finnish",
						},
					]
				}
			],
		},
		{
			key: "star",
			displayName: "Star MAM",
			icon: "star",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "Assets",
							url: "star/search",
							shieldKey: "star.assets",
						},
						{
							displayName: "Albums",
							url: "star/albums",
							shieldKey: "star.albums",
						},
						{
							displayName: "Trashcan",
							url: "star/trashcan",
							shieldKey: "star.trashcan",
						},
						{
							displayName: "Admin",
							url: "star/admin",
							shieldKey: "star.admin",
						},
					],
				},
			],
		},
		{
			key: "storefront",
			displayName: "Storefront",
			icon: "storefront_custom_4",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "On Air",
							url: "selections/service/2",
							shieldKey: "selections.onair",
						},
						{
							displayName: "OTT",
							url: "selections/service/1",
							shieldKey: "selections.ott",
						},
						{
							displayName: "MTV Pressi",
							url: "selections/press",
							shieldKey: "selections.press",
						},
						{
							displayName: "Partner",
							url: "selections/service/4",
							shieldKey: "selections.partner",
						},
						{
							displayName: "Help",
							url: "selections/help",
							shieldKey: "selections.help",
						},
						{
							displayName: "Admin",
							url: "selections/admin",
							shieldKey: "selections.admin",
						},
					],
				},
			],
		},
		{
			key: "shield",
			displayName: "Shield security",
			icon: "security",
			children: [
				{
					key: "group1",
					children: [
						{
							displayName: "Comet users",
							url: "shield/logins-users",
							shieldKey: "shield.users",
						},
						{
							displayName: "API keys",
							url: "shield/logins-apikeys",
							shieldKey: "shield.users",
						},
						{
							displayName: "Horizon users",
							url: "shield/horizon",
							shieldKey: "shield.horizon",
						},
						{
							displayName: "One Stop Drop users",
							url: "shield/onestopdrop",
							shieldKey: "shield.onestopdrop", 
						},
						{
							displayName: "Press users",
							url: "shield/press",
							shieldKey: "shield.press",
						},
						{
							displayName: "Admin",
							url: "shield/admin",
							shieldKey: "shield.modules",
						}
					],
				},
			],
		},
	];
}
