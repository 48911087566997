import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'

import { getProgramLink } from '../../../components/program/utils'

import './broadcast.css'
import './vodpackage.css'

import appConfig from 'config'

const l2vEnabledServices = appConfig.features.internalScheduleL2VEnabledServices.split(",") || [];

export default ({
	broadcast,
	siteName,
	publishingPeriodPlatform,
	getProgramLink: getProgramLinkOverride,
}) => {
	const { seasonNumber, seasonId, seriesId, id, seasonNumberOfEpisodes, programCategory, versions, programType } = getBroadcastMetadata(broadcast);

	const isMissingMetadata = !broadcast.metadata;
	const isMovie = programCategory === "movie" || programType === "single";
	const isAnEpisode = (isMovie || isMissingMetadata) ? false : programType === "episode";
	const isFirstEpisode = (isMovie || isMissingMetadata) ? false : getIsFirstEpisode(broadcast.episodeSpanAndCollapseInfo);
	const isLastEpisode = (isMovie || isMissingMetadata) ? false : getIsLastEpisode(seasonNumberOfEpisodes, broadcast.episodeSpanAndCollapseInfo);
	const seasonEpisode = (isMovie || isMissingMetadata) ? null : getSeasonEpisode(seasonNumber, broadcast.episodeSpanAndCollapseInfo);

	const onLinkClick = React.useCallback(
		(e) => {
			if (isMissingMetadata) {
				e.preventDefault();
			}
		},
		[isMissingMetadata]
	);

	const epgTitle = getEpgTitle(isMissingMetadata, versions);
	const programTitle = epgTitle ?? getProgramTitle(broadcast.name);
	// Because the API returned a few broadcasts with no broadcast.name.
	if (programTitle === null) {
		return null;
	}
	const dubbed = broadcast.type === "dubbed" ? "(dub)" : "";
	const title = `${programTitle} ${dubbed}`.trim();

	const publishPeriodStart = getPremiereStartTime(broadcast.publishingPeriods, siteName);
	const publishPeriodType = getPublishPeriodType(broadcast.publishingPeriods, siteName);
	const lastDayAvailable = getLastDayAvailable(broadcast.publishingPeriods, siteName);
	const hasHvodPublishingPeriod = getHasHvodPublishingPeriod(broadcast.publishingPeriods, publishingPeriodPlatform);

	// Flag next to the title
	const avodPremiere = broadcast.avodPremiere ?? broadcast.servicePremier;
	const svodPremiere = broadcast.svodPremiere ?? broadcast.servicePremier2;
	let broadcastServicePremiereForSite;
	if (siteName === "tv4play") {
		broadcastServicePremiereForSite = avodPremiere;
	} else if (siteName === "tv4playplushvod") {
		broadcastServicePremiereForSite = avodPremiere;
		if (
			svodPremiere && !avodPremiere
			|| (avodPremiere && svodPremiere && moment(svodPremiere).isBefore(avodPremiere))
		) {
			broadcastServicePremiereForSite = svodPremiere;
		}
	} else {
		broadcastServicePremiereForSite = svodPremiere;
	}

	const isPremiere = getIsPremiereFlag(broadcastServicePremiereForSite, publishPeriodStart, isAnEpisode, isFirstEpisode, isMissingMetadata);
	const types = getFlagToType(broadcast.versionName, isLastEpisode, isFirstEpisode, isPremiere, siteName, hasHvodPublishingPeriod, publishingPeriodPlatform); // LIVE, PREM, LOT etc.

	// The flag (1st) in the episode column
	// const isEpisodePremiere = getIsFirstPremiere(broadcast.servicePremier, broadcast.servicePremier2, publishPeriodStart);
	const isEpisodePremiere = getIsPremiere(broadcastServicePremiereForSite, publishPeriodStart);

	return (
		<Link
			to={getVodPackageLink(getProgramLinkOverride, seriesId, seasonId, id)}
			className={`c6-schedule-broadcast vod-package missing-metadata-${isMissingMetadata}`}
			onClick={onLinkClick}
			title={isMissingMetadata ? `${title} (Finns enbart planerad just nu. Metadata saknas.)` : ""}
		>
			<time dateTime={publishPeriodStart.format("HH:mm")}>{publishPeriodStart.format("HH:mm")}</time>
			<h1 title={title}>
				{types.map(t => <span key={t} title={getHoverTitleForFlag(t)} className={`type-${t}`}></span>)}
				{title}
				<span className="season-episode-info">
					<span>{seasonEpisode}</span>
					<span title={"Premiär"} className={`isEpisodePremiere-${isEpisodePremiere}`}></span>
					{publishPeriodType === "tv4-catchup" && <span title={"Catchup"} className="catchUpIcon">{getCatchUpIcon()}</span>}
				</span>
			</h1>
			<time title="Sista tillgängliga dag" dateTime={lastDayAvailable}>{lastDayAvailable}</time>
		</Link>
	);
}

function getVodPackageLink(getProgramLinkOverride, seriesId, seasonId, id) {
	return (getProgramLinkOverride || getProgramLink)(seriesId, seasonId, id);
}

// Could be generalized and remove duplicate code with regards to the method below (getLastDayAvailable)
function getPremiereStartTime(publishingPeriods, siteName) {
	const publishPeriod = getPublishPeriodForSite(publishingPeriods, siteName);

	if (publishPeriod) {
		return moment(publishPeriod.start);
	}
}

function getLastDayAvailable(publishingPeriods, siteName) {
	const publishPeriod = getPublishPeriodForSite(publishingPeriods, siteName);
	return publishPeriod ? moment(publishPeriod.end).format("YYYY-MM-DD") : "";
}

function getPublishPeriodType(publishingPeriods, siteName) {
	const publishPeriod = getPublishPeriodForSite(publishingPeriods, siteName);
	return publishPeriod ? publishPeriod.type : "";
}

function getPublishPeriodForSite(publishingPeriods, siteName) {
	return publishingPeriods.find(p => p.id === siteName);
}

function getHasHvodPublishingPeriod(publishingPeriods, publishingPeriodPlatform) {
	return publishingPeriods.some(p => p.platform === publishingPeriodPlatform && p.type === "hvod");
}

function getEpgTitle(isMissingMetadata, versions) {
	if (isMissingMetadata) return null;

	const version = versions.find(v => v.language === "sv");
	if (version?.epgTitle != null) {
		return version.epgTitle;
	}
	return null;
}

function getProgramTitle(name) {
	// regex conditions
	const regexConditionForSeries = /^[^:]*: (.*):/;
	const regexConditionForMovies = /^[^:]*: ([^:]*)$/;

	// find matches
	const regexMatchesSeries = regexConditionForSeries.exec(name);
	const regexMatchesMovies = regexConditionForMovies.exec(name);

	const newName = regexMatchesSeries !== null ? regexMatchesSeries[1] : regexMatchesMovies[1];

	if (!newName?.length) {
        return null;
    }
    return newName;
}

function getHoverTitleForFlag(flag) {
	switch(flag) {
		case "lot":
			return "Live On Tape";
		case "live":
			return "Live";
		case "last":
			return "Sista eller enda avsnittet i en säsong";
		case "prem":
			// return "Första eller enda avsnittet i en säsong";
			return "Premiär";
		case "l2v":
			return "Live To VOD, streamas först live och ersätts sedan med en klippt version/fil för VOD-uppspelning";
		case "no-hvod":
			return "HVOD-rättigheter saknas för detta program";
		default:
			return "";
	}
}

function getSeasonEpisode(seasonNum, episodeSpanInfo) {

	if (episodeSpanInfo?.firstEpisode === null && episodeSpanInfo?.lastEpisode === null) {
		return "";
	} else if (episodeSpanInfo?.lastEpisode === null) {
		return `S${String(seasonNum).padStart(2, '0')} E${String(episodeSpanInfo?.firstEpisode).padStart(2, '0')}`
	} else {
		return `S${String(seasonNum).padStart(2, '0')} E${String(episodeSpanInfo?.firstEpisode).padStart(2, '0') + "-" + String(episodeSpanInfo?.lastEpisode).padStart(2, '0')}`
	}
}

function getIsFirstEpisode(episodeSpanInfo) {
	return episodeSpanInfo?.firstEpisode === 1 || episodeSpanInfo?.lastEpisode === 1;	
}

function getIsLastEpisode(seasonNumOfEpisodes, episodeSpanInfo) {
	return seasonNumOfEpisodes === episodeSpanInfo?.firstEpisode || seasonNumOfEpisodes === episodeSpanInfo?.lastEpisode;
}

function getIsPremiereFlag(servicePremiere, publishStart, isAnEpisode, isFirstEpisode, isMissingMetadata) {
	if ((isAnEpisode && isFirstEpisode) || (!isAnEpisode && !isMissingMetadata) ) {
		return getIsPremiere(servicePremiere, publishStart);
	}
	return false;
}

function getIsFirstPremiere(servicePremier, servicePremier2, publishStart) {
	const minServicePremiere =  moment.min(
		[
			moment(servicePremier), 
			moment(servicePremier2)
		]
	);
	return getIsPremiere(minServicePremiere, publishStart);
}


function getIsPremiere(servicePremier, publishStart) {
	return servicePremier && publishStart && publishStart.isSame(moment(servicePremier), 'day');
}


function getCatchUpIcon() {
	return (
		<svg style={{}} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="2 7 105.000000 110" preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"> {/* Created by JOL, hence the weird scale and viewbox*/}
				<path d="M76 1113 c-9 -10 -7 -311 2 -317 13 -8 232 143 232 160 0 7 -46 44 
					-102 82 -128 85 -124 83 -132 75z"/>
				<path d="M446 1009 c-57 -45 -17 -119 65 -119 167 0 313 -110 354 -266 19 -73
					19 -105 0 -178 -45 -173 -220 -288 -391 -259 -166 29 -269 135 -299 310 -14
					81 -29 103 -72 103 -35 0 -73 -43 -73 -82 0 -52 27 -154 56 -211 34 -67 144
					-177 211 -211 143 -72 313 -72 456 0 67 34 177 144 211 211 72 143 72 313 0
					456 -34 67 -144 177 -211 211 -110 55 -259 73 -307 35z"/>
			</g>
		</svg>
	);
}

function getFlagToType(versionName, isLastEpisode, isFirstEpisode, isPremiere, siteName, hasHvodPublishingPeriod) {
	const types = [];
	const isLOT = versionName === "LOT";
	const isLIVE = versionName === "LIVE";

	if (["tv4play", "tv4playplus"].includes(siteName) && !hasHvodPublishingPeriod)  {
		types.push("no-hvod");
	}
	// Don't present PREM if package is LOT or LIVE, because that's a given
	if (isPremiere && ((!isLOT && !isLIVE) || isFirstEpisode)) {
		types.push("prem");
	}
	if (isLOT) {
		types.push("lot");
	}
	if (isLIVE) {
		types.push("live");
	}
	if (isLastEpisode) {
		types.push("last");
	}
	if (l2vEnabledServices?.includes(siteName) && ["LOT", "LIVE"].includes(versionName)) {
		types.push("l2v");
	}

	return types;
}

function getBroadcastMetadata(broadcast) {
	return {
		...broadcast.metadata,
		seriesId: broadcast.metadata?.seriesInfo?.id,
		id: broadcast.metadata?.id,
		seasonId: broadcast.metadata?.seasonInfo?.id,
		seasonNumber: broadcast.metadata?.seasonInfo?.seasonNumber,
		seasonNumberOfEpisodes: broadcast.metadata?.seasonInfo?.numberOfEpisodes,
		programCategory: broadcast.metadata?.programCategory,
		versions: broadcast.metadata?.versions,
		programType: broadcast.metadata?.programType
	}
}