import cloneDeep from 'lodash/cloneDeep'

import appConfig from 'config'
import alt from '../../core/services/alt'
import Actions from './actions'

class PressStore {

    constructor() {

		this.isLoading = false;

        this.item = {
			isLoading: false,
			siteStructure: {},
			program: {},
			season: {},
			series: {},
			pressRelease: {},
			bulletinBoardItem: {},
			programSchedules: {},
			programReviews: {},
        };

		// Items/List
		this.list = {};
		this.initStore("schedules", { _channelGroup: "" });
		this.initStore("discovery");
		this.initStore("channelGroups");

		this.useChannelGroupsFallback = false;
		this.previewAsset = null;
		this.assetInfoForAnalytics = null;

		this.filters = {
			filter: {
			},
			apikey: appConfig.api.contentKey || "",
		};
		this._prevFilter = null;
		setDependentFilters(this.filters, this.filters.searchText);

        this.bindListeners({
			// FILTERS
			onSearch: Actions.SEARCH,
			onFilter: Actions.FILTER,

            // LISTS
            onFetchItems: Actions.FETCH_ITEMS,
            onPageItems: Actions.PAGE_ITEMS,
            onItemsUpdated: Actions.ITEMS_UPDATED,

            // ITEM
            onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
            onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

            // GENERAL
            onUnmount: Actions.UNMOUNT,
			onPersist: Actions.PERSIST,

			onPreviewAsset: Actions.PREVIEW_ASSET,
			onScheduleFilter: Actions.SCHEDULE_FILTER,
        });
	}
	
	// FILTERS
	onSearch({ searchText: text, targetStore }) {
		if (text === "") {
			this.filters.filter = this._prevFilter;
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {};
		}
		this.filters.searchText = text;
		this.list[targetStore || "files"].items = [];
		setDependentFilters(this.filters, text);
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = {
			...filterParams,
			...filterPayload,
		};

		this.unselectedFilter = false;
		this.filters.searchText = "";
		this._prevFilter = null;

		this.list[targetStore || "files"].items = [];
		setDependentFilters(this.filters, this.filters.searchText);
	}

    // Items
    onFetchItems({ payload, store, requestTime }) {
        const l = this.list[store];
        l.isLoading = true;

		l.nextPageUrl = null;
		l.numberOfItems = 0;
        l.latestRequestTime = requestTime;
        l.latestSearchPayload = payload;
	}

	onPageItems({ entity, store }) {
        const l = this.list[store || entity];
        l.isLoading = true;
		l.nextPageUrl = null;
	}

    onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
        const l = this.list[store];

		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

			if (store === "channelGroups" && !items?.length) {
				this.useChannelGroupsFallback = true;
			}

            l.isLoading = false;
		}
    }

    /* Item */
	onFetchItem(entity) {
		this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	onItemSaved({ entity, model, targetStore = null }) {
		const store = targetStore || `${entity}s`;

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.item.isLoading = false;
    }

    onRemoveItem({ entity, id, targetStore }) {
		this.isLoading = true;

		const item = this.getItem(entity, id, targetStore);
		item._isHidden = true;
    }
	onItemRemoved({ entity, id, targetStore }) {
		this.isLoading = false;

		this.removeItem(entity, id, targetStore);
	}
	onRollbackRemoveItem({ entity, id, targetStore }) {
		this.isLoading = false;

		const item = this.getItem(entity, id, targetStore);
		delete item._isHidden;
	}

    /* =============== */
    /*    GENERAL      */
    /* =============== */
    onUnmount() {
		// this.initFilters();

    }

    initStore(store, filters = null, extras = {}, items = [], ) {
		this.list[store] = {
            items,
			nextPageUrl: null,
			numberOfItems: 0,
            latestRequestTime: null,
            latestSearchPayload: null,
            filters,
            isLoading: false,
            ...extras,
		};
    }

    onPersist({ store, model, prepend = false }) {
		const storeItems = this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(si => si.id === model.id);

		if (index >= 0) {
			storeItems[index] = model;
        }
        else if (prepend) {
			storeItems.unshift(model);
        }
        else {
            storeItems.push(model);
        }

		return true;
	}


	onPreviewAsset({ asset, assetInfoForAnalytics }) {
		this.previewAsset = asset;
		this.assetInfoForAnalytics = assetInfoForAnalytics;
	}

	onScheduleFilter(payload) {
		this.list.schedules.filters = {
			...this.list.schedules.filters,
			...payload,
		};
	}

	onFetchChannelGroups() {
		this.onFetchItems({ store: "channelGroups" });
	}

	onUseChannelGroupsFallback() {
		this.useChannelGroupsFallback = true;
		this.onItemsUpdated({ datastore: "channelGroups", items: [], appendToExistingItems: false, numberOfItems: 0 });
	}
}

export default alt.createStore(PressStore, '[Press]Store');

/* Helpers */
function setDependentFilters(/*filters, searchText*/) {
	// filters.orderBy = "updated";
	
	// if (searchText && searchText !== "") {
	// 	filters.orderBy = "name";
	// }
}