import React from 'react'
import moment from 'moment'

import GenericApp from '../../../components/genericApp'
import Main from '../../../components/ui/main'
import Schedule from '../../internal-schedule/schedule/schedule'
import Broadcast from './broadcast'

import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import { getItems } from '../../../components/genericApp/utils'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import { EVENTPLANNER_DATE_FILTER } from '../shared/utils'

import '../../internal-schedule/schedule/app.css'
import '../../internal-schedule/shared/channels.css'
import './app.css'

import appConfig from 'config'

const MODULE = "eventplanner";
const DATASTORE = "schedule";
const TEXT_HEADING = null;//"Events";
const TEXT_BACK_BUTTON = null;//"Administration"
const TEXT_EMPTY = null;//"No events? It's about time to create some then!";
const TEXT_CREATE = null;//"Create event";
const ICON_CREATE = null;//"add";

const START_FILTER_KEY = "schedule.day";
const _entity = "broadcasts";
const _module = "broadcast";

function ListApp(props) {

	const channels = filterChannels(appConfig.features.eventplannerScheduleChannels ?? DEFAULT_CHANNELS);

	const channelGroups = getChannelGroups(channels);
	const filterConfig = {
		[START_FILTER_KEY]: {
			type: "date",
			alwaysVisible: true,
			onChange: (newValue) => {
				// Clear stored value when selecting today
				const isToday = moment(newValue).isSame(moment(), "day");
				setUniversalEventPlannerDate(isToday ? undefined : moment(newValue).startOf("day").format());
			},
		},
		"prev": {
			type: "button",
			buttonType: "prev",
			alwaysVisible: true,
			onClick: () => updateStart(-1),
		},
		"next": {
			type: "button",
			buttonType: "next",
			alwaysVisible: true,
			onClick: () => updateStart(1),
		},
		_channelGroup: {
			onlyUI: true,
			type: "switch",
			title: "Channels",
			options: channelGroups,
			alwaysVisible: true,
		},
	};

	if (channelGroups.length <= 1) {
		delete filterConfig._channelGroup;
	}

	const [universalEventPlannerDate, setUniversalEventPlannerDate] = useSessionStorage(EVENTPLANNER_DATE_FILTER, moment().startOf("day").format());
	const dateFromQuery = props.location.query?.date;
	const FILTER_DEFAULTS = {
		_entity,
        _module,
		[START_FILTER_KEY]: dateFromQuery ? moment(dateFromQuery, "YYYY-MM-DD").startOf("day").format() : universalEventPlannerDate,
		pageSize: 10000,
		_channelGroup: channelGroups[0].key,
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: CMSAPI.fetchCMSEntities,
		transformFilters: transformFilters,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		module: _module,
		updateDataExtraParams: { _entity, _module },
		filterDefaults: FILTER_DEFAULTS,
		filterConfig: filterConfig,
	});

	const updateStart = React.useCallback(
		(inc) => {
			const updateMethod = listState.filters.updateMethods[START_FILTER_KEY];
			const newStart = moment(listState.filters.values[START_FILTER_KEY]).add(inc, "days");
			updateMethod(newStart.format("YYYY-MM-DD"));
		},
		[listState.filters.values[START_FILTER_KEY], listState.filters.updateMethods[START_FILTER_KEY]]
	);

    const refetchTimeout = React.useRef();
    React.useEffect(
        () => {
            // Refetching will trigger this effect again so it's effectively an interval and not a timeout
            clearTimeout(refetchTimeout.current);
            refetchTimeout.current = setTimeout(() => {
                listState.refetch();
            }, 5 * 60 * 1000); // Refresh every five minutes.

            return () => clearTimeout(refetchTimeout.current);
        },
        [listState.refetch, listState.latestFetchTimestamp.current]
    );

    const items = getItems(listState.data);
	const schedules = getSchedules(items, channels, listState.filters.values._channelGroup);

	return (
		<React.Fragment>
			<GenericApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={filterConfig}
				collapseFiltersDefault={true}
				className="c6-eventplanner-schedule c6-internal-schedule c6-list"
			>
				<Main>
					<Schedule
						schedules={schedules}
						channels={getChannels(channels)}
					>
						<Broadcast
							onChange={listState.onChange}
							channelGroup={listState.filters.values._channelGroup.toLowerCase().replace(" ", "")}
						/>
					</Schedule>
				</Main>
			</GenericApp>
		</React.Fragment>
	);
}

export default class ScheduleApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}


export function transformFilters(filters) {
	const transformed = { ...filters };

	// The channel group filter is only used by the frontend
	delete transformed["_channelGroup"];

	return transformed;
}

function getSchedules(items, channels, channelGroup) {
    const schedules = [];

    items.forEach(broadcast => {
		// Only include broadcasts for selected channels
		const channelName = broadcast.schedule.channel.name;
		const channel = channels.find(c => c.name.toLowerCase() === channelName.toLowerCase());
		if (!channel || channel.group !== channelGroup) {
			return;
		}

		const channelId = broadcast.schedule.channel.id;
        let schedule = schedules.find(s => s.id === channelId);
        if (!schedule) {
            schedule = {
                id: channelId,
                channel: {
                    id: broadcast.schedule.channel.id,
                    name: channel.reference,
                    displayName: channel.displayName ?? channel.name,
                },
                day: broadcast.schedule.day,
                broadcasts: []
            };
            schedules.push(schedule);
        }

        const broadcastItem = {
			...broadcast,

			// TODO: Are these mappings necessary?
			// Could maybe just access the texts directly in the Broadcast component
            title: broadcast.titles["se_sv"],
            broadcastText: broadcast.descriptions["se_sv"],
            broadcastTitle: broadcast.title,

			_originalItem: broadcast,
        };
        schedule.broadcasts.push(broadcastItem);
        // const indexOfCurrentBroadcast = schedule.broadcasts.findIndex(br => moment(br.public.start).isSameOrBefore(now) && moment(br.public.end).isSameOrAfter(now));
        // const filteredBroadcasts = indexOfCurrentBroadcast > 0
        //     ? schedule.broadcasts.slice(indexOfCurrentBroadcast - 1) // HACK?: Include one more which we hide through CSS, in order to get the first active broadcast highlighted
        //     : schedule.broadcasts;
        // return {
        //     ...schedule,
        //     broadcasts: filteredBroadcasts,
        // }
    });

	schedules.forEach(schedule => {
		schedule.broadcasts.sort((brA, brB) => (brA.start ?? "0").localeCompare(brB.start ?? "0", undefined, { numeric: true }));
	});

    return schedules;
}

function getChannels(items) {
    return items.map(item => ({
        key: item.name.toLowerCase(),
        text: item.name,
    })) ?? [];
}

function getChannelGroups(channels) {
	return channels.reduce((groups, channel) => {
		if (!groups.some(g => g.key === channel.group)) {
			return [...groups, { key: channel.group, text: channel.group }];
		}

		return groups;
	}, []);
}

function filterChannels(channels) {
	let filteredChannels = [...channels];
	if (appConfig.features.hideTV4Channels) {
		filteredChannels = filteredChannels.filter(c => c.group !== "TV4");
	}
	return filteredChannels;
}

const DEFAULT_CHANNELS = [
	{
		"reference": "tv4",
		"name": "TV4",
		"group": "TV4"
	},
	{
		"reference": "sjuan",
		"name": "SJUAN",
		"group": "TV4"
	},
	{
		"reference": "tv12",
		"name": "TV12",
		"group": "TV4"
	},
	{
		"reference": "tv4-fakta",
		"name": "TV4 FAKTA",
		"group": "TV4"
	},
	{
		"reference": "tv4-guld",
		"name": "TV4 GULD",
		"group": "TV4"
	},
	{
		"reference": "tv4-film",
		"name": "TV4 FILM",
		"group": "TV4"
	},
	{
		"reference": "tv4-hits",
		"name": "TV4 Hits",
		"group": "TV4"
	},
	{
		"reference": "tv4-stars",
		"name": "TV4 Stars",
		"group": "TV4"
	},
	{
		"reference": "sf-kanalen",
		"name": "SF-Kanalen",
		"group": "TV4"
	},
	{
		"reference": "tv4-fotboll",
		"name": "TV4 Fotboll",
		"group": "TV4"
	},
	{
		"reference": "tv4-first",
		"name": "TV4 First",
		"group": "TV4"
	},
	{
		"reference": "tv4-hockey",
		"name": "TV4 Hockey",
		"group": "TV4"
	},
	{
		"reference": "tv4-tennis",
		"name": "TV4 Tennis",
		"group": "TV4"
	},
	{
		"reference": "tv4-motor",
		"name": "TV4 Motor",
		"group": "TV4"
	},
	{
		"reference": "tv4-sportkanalen",
		"name": "TV4 Sportkanalen",
		"group": "TV4"
	},
	{
		"reference": "tv4-live-1",
		"name": "TV4 Live 1",
		"group": "TV4"
	},
	{
		"reference": "tv4-live-2",
		"name": "TV4 Live 2",
		"group": "TV4"
	},
	{
		"reference": "tv4-live-3",
		"name": "TV4 Live 3",
		"group": "TV4"
	},
	{
		"reference": "tv4-live-4",
		"name": "TV4 Live 4",
		"group": "TV4"
	},
];