import React, { Component } from 'react'
import { browserHistory } from 'browserHistory'

import PressSearch from './search'
import Link from '../shared/link'

import Translations from '../shared/translations'

import AuthActions from '../../../core/authentication/actions'
import * as PressAPI from '../../../apis/press'
import { shouldShowItemForUserBasedOnAudience } from '../shared/utils'

import './header.css'

import appConfig from 'config'

export default class PressHeader extends Component {
	state = {
		hamburgerOpen: false,
	}

	onUserClick = () => {
		this.setState({ hamburgerOpen: false });
		
		const { loggedIn, user } = this.props;
		if (loggedIn) {
			// log out
			AuthActions.logout(user, true, PressAPI);
			browserHistory.push({ pathname: "/" });
		} else {
			// log in
			const loginPage = (this.props.pages || []).find(p => p.path === "/kirjaudu") ? "kirjaudu" : "login";
			browserHistory.push({
				pathname: `/${loginPage}`,
				state: location.pathname.endsWith("/logout") || location.pathname.endsWith("/login")
					? location.state ?? undefined
					: { nextLocation: { ...location } },
			});
		}
	}

	render() {
		const { loggedIn, pages, searchResults } = this.props;

		const showSearch = appConfig.features.pressEnableSearch && loggedIn;

		return (
			<header className="c6-press-header">
				<div className="header-container">
					<Link
						to="/"
						className="logo"
						onClick={(e) => {
							if (location.pathname === "/") {
								e.preventDefault();
								window.scrollTo({ top: 0, behavior: "smooth" });
							}
							this.setState({ hamburgerOpen: false })
						}}
					>
						<img
							src={appConfig.features.pressLogoPath}
							alt="Logo"
							decoding="async"
						/>
						<h1>{appConfig.features.pressSiteTitle}</h1>
					</Link>
					{/* Desktop navigation */}
					<nav>
						<div className="links">
							{getLinks(pages, null, loggedIn)}
						</div>
					</nav>
					<div className="actions">
						{showSearch && (
							<PressSearch
								searchResults={searchResults}
								onSearchClick={() => this.setState({ hamburgerOpen: false })}
							/>
						)}
						<Link onClick={this.onUserClick}>
							{!loggedIn && <UserIcon />}
							{loggedIn && <span>{Translations.getTranslation("logOut")}</span>}
						</Link>
						{/* Mobile navigation */}
						<div className="mobile-nav">
							<input
								type="checkbox"
								checked={this.state.hamburgerOpen}
								onChange={e => this.setState({ hamburgerOpen: e.target.checked })}
							/>
							{/* 3 spans for the hamburger */}
							<span className="top"></span>
							<span className="middle"></span>
							<span className="bottom"></span>
							<div className="links">
								{getLinks(pages, () => this.setState({ hamburgerOpen: false }), loggedIn)}
								<hr/>
								<Link onClick={this.onUserClick}>
									{Translations.getTranslation(loggedIn ? "logOut" : "logIn")}
								</Link>
							</div>
						</div>
						{/*<button>Login</button>*/}
					</div>
				</div>
			</header>
		);
	}
}

const getLinks = (pages = [], onClick, loggedIn) => {
	if (appConfig.features.pressHeaderScrollToLinks?.length) {
		return appConfig.features.pressHeaderScrollToLinks.split(",").map(link => {
			const [widgetId, text] = link.split(":");
			return (
				<a
					href={`#${widgetId}`}
					key={widgetId}
					onClick={(e) => {
						e.preventDefault();
						document.getElementById(widgetId).scrollIntoView({ behavior: "smooth", block: "start" });
						if (onClick) {
							onClick(e);
						}
					}}
				>
					{text}
				</a>
			)
		});
	}

	return pages
		.filter(s =>
			!s.hidden
			&& s.path !== "/"
			&& shouldShowItemForUserBasedOnAudience(s.audience, loggedIn)
		)
		.map(s => (
			<Link key={s.path} to={s.path} activeClassName="active" onClick={onClick}>{s.title}</Link>
		));
};

const UserIcon = () => (
	<svg viewBox="0 0 24 24">
		<title>{Translations.getTranslation("logIn")}</title>
		<path d="M3.6,21.4c-0.6,0-1-0.4-1-1c0-3.5,2.6-4.6,4.2-5.4c0.5-0.2,1.4-0.6,1.4-0.9c0-0.1-0.1-0.2-0.3-0.5  c-0.5-0.8-1.4-2-1.4-4.9C6.6,5.3,9,2.6,12,2.6s5.4,2.7,5.4,6.1c0,2.6-0.8,3.9-1.3,4.8c-0.2,0.3-0.3,0.6-0.3,0.7  c0,0.2,0.9,0.7,1.5,0.9c1.7,0.7,4.2,1.9,4.2,5.1c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-1.8-1.2-2.4-3-3.2c-1.3-0.6-2.7-1.2-2.7-2.7  c0-0.7,0.3-1.2,0.6-1.7c0.5-0.8,1-1.7,1-3.7c0-2.3-1.5-4.1-3.4-4.1S8.6,6.4,8.6,8.7c0,2.3,0.6,3.1,1,3.8c0.3,0.4,0.6,0.9,0.6,1.7  c0,1.5-1.4,2.1-2.6,2.7c-1.8,0.8-3.1,1.5-3.1,3.5C4.6,20.9,4.1,21.4,3.6,21.4z"></path>
	</svg>
);