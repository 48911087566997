import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

// import Const from '../../../core/constants'

import Channel from './channel'
import VodPackage from './vodpackage'

export default class Schedule extends React.PureComponent {

	static propTypes = {
		schedules: PropTypes.array.isRequired,
		channels: PropTypes.array.isRequired,
		children: PropTypes.object.isRequired,
		noBroadcastsText: PropTypes.string,
		noBroadcastsMatchingFilterText: PropTypes.string,
	}

	renderVodPackageChannel(vodPackages, group, channelSiteName, displayName, day, publishingPeriodPlatform, publishingPeriodType, excelDownloadSites) {
		const dayScheduleStart = Moment(day); // Example 2022-05-01 00:00:00
		const dayScheduleEnd = Moment(day).add(1, "day"); // With the example above 2022-05-02 00:00:00

		const broadcastsFromVodPackages = vodPackages
			.filter(b => {
				const hasMatchingActivePublishingPeriod = b.publishingPeriods.some(p => {
					return (p.platform === publishingPeriodPlatform || !p.platform && p.id === channelSiteName)
						&& (!publishingPeriodType || p.type === publishingPeriodType)
						&& Moment(p.start).isBetween(dayScheduleStart, dayScheduleEnd, undefined, '[)')
				});
				return hasMatchingActivePublishingPeriod;
			})
			.sort((p1, p2) => p1.name.localeCompare(p2.name)) // Sort by name
			.sort((p1, p2) => compareByPublishWindows(p1, p2, channelSiteName)) // Sort by start (will have higher priority than name sort)
			.filter((b, i, l) => l.findIndex(b2 => b2.packageId === b.packageId) === i) // Remove duplicates

		// Match and tag for collapse function
		const broadcastsWithCollapseInfo = broadcastsFromVodPackages.map((broadcast) => ({
			...broadcast,
			episodeSpanAndCollapseInfo: checkEpisodeAndCollapseInfo(broadcast, broadcastsFromVodPackages, channelSiteName),
		}));

		return (
			<Channel
				group={group}
				channel={{
					channel: { name: channelSiteName, displayName: displayName },
					broadcasts: broadcastsWithCollapseInfo.filter(b => !b.episodeSpanAndCollapseInfo?.collapse) // If collapse is true filter them out.
				}}
				noBroadcastsText={""}
				renderChannelName={displayName?.length}
				vodPlatform={publishingPeriodPlatform}
				vodType={publishingPeriodType}
				excelDownloadSites={excelDownloadSites}
			>
				<VodPackage
					siteName={channelSiteName}
					publishingPeriodPlatform={publishingPeriodPlatform}
				/>
			</Channel>
		);
	}

	render() {
		const {
			schedules: scheduleChannels,
			channels,
			noBroadcastsText,
			children,
			tv4PlayPackages,
			tv4PlayPlusPackages,
			selectedChannels,
			day,
			getChannelHeaderExtra,
			copyProgramInfoButtonText,
			copyScheduleForDay,
		} = this.props;
		// const firstChannel = scheduleChannels && scheduleChannels.length && scheduleChannels[0];
		// const dayTitle = firstChannel ? Moment(firstChannel.day).format(Const.PERIOD_FORMAT) : null;

		const selectedChannelsArr = selectedChannels?.split(",") ?? [];
		const includeVodTV4Play = selectedChannelsArr.includes("tv4play");
		const includeVodTV4PlayPlusHVOD = selectedChannelsArr.includes("tv4play-hvod");
		const includeVodTV4PlayPlusSVOD = selectedChannelsArr.includes("tv4play-svod");

		return (
			<div className="c6-schedule">
				{scheduleChannels.map(channel => {
					const currentChannel = channels.find(c => c.key === channel.channel.name);
					const group = currentChannel?.group;
					return (
						<Channel
							key={channel.channel.id}
							day={day}
							group={group}
							channel={channel}
							renderChannelName={channel.channel.renderChannelName || ["hidden-virtual"].includes(group)}
							channelDisplayName={currentChannel?.text}
							noBroadcastsText={noBroadcastsText}
							getChannelHeaderExtra={getChannelHeaderExtra}
							copyScheduleForDay={copyScheduleForDay}
							copyProgramInfoButtonText={copyProgramInfoButtonText}
						>
							{children}
						</Channel>
					);
				})}
			
				{/* Renders only if the sites are included/selected in the channel list */}
				{includeVodTV4Play && this.renderVodPackageChannel(tv4PlayPackages, "tv4", "tv4play", "TV4Play", day, "tv4play", "avod", "tv4play")}
				{includeVodTV4PlayPlusHVOD && this.renderVodPackageChannel(tv4PlayPlusPackages, "tv4", "tv4playplushvod", "TV4Play+ HVOD", day, "tv4play", "hvod", "tv4play,tv4playplus,tv4playplushvod")}
				{includeVodTV4PlayPlusSVOD && this.renderVodPackageChannel(tv4PlayPlusPackages, "tv4", "tv4playplus", "TV4Play+ SVOD", day, "tv4play", "svod", "tv4play,tv4playplus")}
			</div>
		);
	}
}

function compareByPublishWindows(p1, p2, channelSiteName) {
	try {
		const date1 = Moment(p1.publishingPeriods.find(p => p.id === channelSiteName)?.start);
		const date2 = Moment(p2.publishingPeriods.find(p => p.id === channelSiteName)?.start);
		return date1.unix() - date2.unix();
	} catch {
		return 0;
	}
}

function getBroadcastMetadata(broadcast, siteName) {
	return {
		...broadcast.metadata,
		packageId: broadcast.packageId ?? null,
		seriesId: broadcast.metadata?.seriesInfo?.id ?? null,
		seasonNumber: broadcast.metadata?.seasonInfo?.seasonNumber ?? null,
		episodeNumber: broadcast.metadata?.episodeNumber ||  null,
		start: broadcast.publishingPeriods?.find(p => p.id === siteName)?.start ?? null,
		programCategory: broadcast.metadata?.programCategory,
		programType: broadcast.metadata?.programType
	}
}

function sortByEpisodeNumber(ep1, ep2) {
	if (!ep1.metadata || !ep2.metadata) {
		return 0;
	}

	if (ep1.metadata.episodeNumber < ep2.metadata.episodeNumber) {
		return -1;
	}
	if (ep1.metadata.episodeNumber > ep2.metadata.episodeNumber) {
		return 1;
	}
	return 0;
  }

// Checks and adds a flag to episodes with the same start time, serieId and seasonNum for collapse
function checkEpisodeAndCollapseInfo(broadcast, broadcasts, siteName) {
	// This function and the collapse functionality is dependent on Metadata being available
	if (!broadcast.metadata) {
		// We return collapse=false as the result can be erroneous if we don't
		return getEpisodeSpanAndCollapseInfo(null, null, false);
	}

	const { seriesId: seriesId1, seasonNumber: seasonNum1, programCategory, programType, start: start1 } = getBroadcastMetadata(broadcast, siteName);

	// Movies and singles don't have/need episode-collapse information
	if (programCategory === "movie" || programType === "single") {
		return null;
	}

	// Mandatory information for the episode-collapse functionality
	if (isMissingRequiredData(seriesId1, seasonNum1, start1)) {
		return null;
	}

	// Get episodes that should collapse (meeting the conditions below)
	const results = broadcasts.filter((entry) => {
		const { seriesId: seriesId2, seasonNumber: seasonNum2, start: start2 }  = getBroadcastMetadata(entry, siteName);
		return seriesId1 === seriesId2
			&& seasonNum1 === seasonNum2
			&& start1 === start2;
	}).sort(sortByEpisodeNumber);

	const firstEp = getBroadcastMetadata(results[0], siteName);
	const lastEp = getBroadcastMetadata(results[results.length-1], siteName);
	
	// If it does have siblings and it's the first episode
	if (results.length > 1 && (firstEp.packageId === broadcast.packageId)) {
		return getEpisodeSpanAndCollapseInfo(firstEp.episodeNumber, lastEp.episodeNumber, false);
	}
	
	// If it's the only episode, it should get a epi number.
	if (firstEp.packageId === lastEp.packageId) {
		return getEpisodeSpanAndCollapseInfo(firstEp.episodeNumber, null, false);
	}

	// Episodes that are part of a collapse
	return getEpisodeSpanAndCollapseInfo(null, null, true);
}

function getEpisodeSpanAndCollapseInfo(firstEp, lastEp, collapse) {
	return {
		firstEpisode: firstEp,
		lastEpisode: lastEp,
		collapse: collapse ?? false
	};
}

function isMissingRequiredData(seriesId, seasonNum, publishWindowStart) {
	return (
			seriesId === null
			|| seasonNum === null
			|| publishWindowStart === null
			|| seriesId === undefined
			|| seasonNum === undefined
			|| publishWindowStart === undefined
	);
}
