import React from 'react'
import { Link } from 'react-router'

import { getItemHref } from '../shared/utils'
import Widget from './widget'

import appConfig from 'config'

import './highlights.css'

const Highlights = ({ widgetData = {}, extended }) => {
	const items = widgetData.items || [];

	return (
		<div className={`c6-press-highlights item-count-${items.length}`}>
			{items.map(item => <HighlightItem key={item.id} {...item} extended={extended} />)}
		</div>
	);
}

export default Widget(Highlights);

const HighlightItem = (props) => {
	const { type, heading, description, assets: itemAssets = [], program = {}, extended } = props;
	const { assets: programAssets = [], tags = [] } = program;
	const mainImage = itemAssets.find(a => a.type === "image") || programAssets.find(a => a.type === "image");
	const brandTags = getBrandTags(tags);

	const href = getItemHref(props);
	const content = (
		<div>
			{mainImage && (
				<img
					src={`${appConfig.media.star}${mainImage.id}/Comet_16x9_800x450.jpg`}
					alt=""
					decoding="async"
				/>
			)}
			{/* TODO: Use below URL when the images in Azure blob storage works */}
			{/*mainImage && <img src={`${appConfig.media.star}44/${mainImage.id}.jpg`} alt={heading} />*/}
			<div className="brand-tags">
				{brandTags.map(t => <span key={t} className={`brand-tag channel-logo channel-${t}`}></span>)}
			</div>
			{(heading || program.title || description || program?.synopsis?.short) && (
				<div className="text">
					<h2>{heading || program.title}</h2>
					{extended && <p>{description || program?.synopsis?.short || ""}</p>}
				</div>
			)}
		</div>
	);

	const className = `c6-press-highlight-item ${type} ${extended ? "extended" : ""}`;

	if (!href || href === "") {
		return <div className={className}>{content}</div>;
	}

	// Use a regular anchor tag instead of react-router link if the link is not to this site
	if (isExternalLink(href)) {
		return <a href={href} className={className}>{content}</a>
	}

	return (
		<Link className={className} to={href}>{content}</Link>
	);
}

function getBrandTags(tags = []) {
	const brandTags = tags.filter(t => t.type === "Brand");
	return brandTags.map(t => t.name.toLowerCase().replace(/\s/g, ""));
}

function isExternalLink(href) {
	if (
		href.startsWith("http://")
		|| href.startsWith("https://")
		|| href.startsWith("//")
	) {
		const a = document.createElement("a");
		a.href = href;
		return a.hostname !== window.location.hostname;
	}

	return href.startsWith("mailto");
}