import { useState } from 'react'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'

import Widget from './widget'

import './scheduleList.css'

const ScheduleList = (props) => {
    const { widgetData = {} } = props;
	const { items } = widgetData;

    const [maxVisible, setMaxVisible] = useState(15); // Number of broadcasts to show initially

    // Don't show more than one broadcast with the same date and heading
    const uniqueItems = uniqBy(items ?? [], item => moment(item.start).format("YYYY-MM-DD") + item.heading);

    // There should only be upcoming broadcasts from backend but we filter them here just in case there is a delay
    const startOfToday = moment().startOf("day");
    const upcomingBroadcasts = uniqueItems.filter(item => moment(item.start).isAfter(startOfToday));

    // Filtering the broadcasts into two lists
    const sportkanalenBroadcasts = upcomingBroadcasts.filter(item => item.channel === "tv4-sportkanalen") ?? [];
    const visibleSportkanalenBroadcasts = sportkanalenBroadcasts.slice(0, maxVisible);
    const remainingChannelsBroadcasts = upcomingBroadcasts.filter(item => item.channel !== "tv4-sportkanalen") ?? [];
    const visibleRemainingChannelsBroadcasts = remainingChannelsBroadcasts.slice(0, maxVisible);

    return (
        <div className="c6-partner-schedule-list">
            <div className="schedules">

                {/* Schedules of other channels */}
                <div>
                    <h3>Sport på TV4:s sportkanaler</h3>
                    <ul>
                        {visibleRemainingChannelsBroadcasts?.map((broadcast) => (
                            <li key={broadcast.id}>
                                <div className="partner-broadcast">
                                    {moment(broadcast.start).format("DD MMM")} - {broadcast.heading}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Schedules of Sportkanalen */}
                <div>
                    <h3 className="channel-logo channel-tv4-sportkanalen"></h3>
                    <ul>
                        {visibleSportkanalenBroadcasts?.map((broadcast) => (
                            <li key={broadcast.id}>
                                <div className="partner-broadcast">
                                    {moment(broadcast.start).format("DD MMM")} - {broadcast.heading}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Show more button */}
            {(maxVisible < sportkanalenBroadcasts.length || maxVisible < remainingChannelsBroadcasts.length) && (
                <div className="show-more">
                    <a className="c6-link" onClick={() => setMaxVisible(value => value + 15)}>Visa mer kommande sport</a>
                </div>
            )}
        </div>
    );
}

export default Widget(ScheduleList);