import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'browserHistory'
import moment from 'moment-timezone'

import ResponsiveImage from '../assets/responsiveImage'

import {
	getProgramLink,
	getSynopsis,
	getSwedishVersion,
	getUpcomingBroadcasts,
	renderBroadcasts,
	getUpcomingEpisode,
	getBroadcastText,
} from './utils'

import PressImages from './pressImages'

import appConfig from 'config'

import './program.css'
import './pressProgram.css'
import './sportSeason.css'

export default class Program extends React.PureComponent {

	static propTypes = {
		program: PropTypes.object,
		season: PropTypes.object,
		episodes: PropTypes.array,
		id: PropTypes.string,
		seasonId: PropTypes.string,
		seriesId: PropTypes.string,
		isLoading: PropTypes.bool,
		onPressImageClick: PropTypes.func,
		seasons: PropTypes.array,
		getSeasonText: PropTypes.func,
		programSchedules: PropTypes.object,
		allowPressImageDownload: PropTypes.bool,
		allowPressImagePreview: PropTypes.bool,
		texts: PropTypes.object,
		getProgramLink: PropTypes.func,
		timezone: PropTypes.string,
	}

	render() {
		const {
			id,
			seasonId,
			seriesId,
			program = {},
			season,
			episodes,
			// isLoading,
			onPressImageClick,
			// seasons = [],
			// getSeasonText,
			programSchedules,
			allowPressImageDownload,
			allowPressImagePreview,
			texts = {},
			getProgramLink: getProgramLinkOverride,
			timezone,
			series,
			pageName,
		} = this.props;

		const currentEpisode = id ? (episodes || []).find(e => e.id === id) : getUpcomingEpisode(episodes);
		const provider = program?.provider || season?.provider;

		return (
			<div className={`c6-program ${season?.id ? "program-season" : ""} press-program sport`}>
				<div className="section">
					<SeasonPart
						{...this.props}
						program={season}
						id={id || currentEpisode && currentEpisode.id}
						provider={provider}
						getProgramLinkOverride={getProgramLinkOverride}
					/>
					<hr />
					<PressImages
						main={season}
						series={series}
						onClick={onPressImageClick}
						allowDownload={allowPressImageDownload}
						allowPreview={allowPressImagePreview}
						texts={texts}
						provider={provider}
					/>
				</div>
				<div className="section">
					{currentEpisode && (
						<React.Fragment>
							<ProgramPart
								{...this.props}
								id={id || currentEpisode?.id}
								program={currentEpisode}
								allowPreview={allowPressImagePreview}
								onVideoClick={onPressImageClick}
								pageName={pageName}
							/>
							<hr/>
							<PressImages
								main={currentEpisode}
								onClick={onPressImageClick}
								allowDownload={allowPressImageDownload}
								allowPreview={allowPressImagePreview}
								texts={texts}
								provider={provider}
							/>
							<hr />
						</React.Fragment>
					)}
					{getEpisodes({
						episodes,
						episodeId: id || currentEpisode?.id,
						seasonId,
						seriesId,
						texts,
						getProgramLinkOverride,
						programSchedules,
						timezone,
						series,
						season,
					})}
				</div>
			</div>	
		);
	}
}

// When rendering single programs or episodes
const ProgramPart = ({
	program,
	programSchedules,
	texts,
	timezone,
	id,
	season,
	allowPreview,
	onVideoClick,
	pageName,
}) => {
	const upcomingBroadcasts = getUpcomingBroadcasts(programSchedules, season?.type === "season" ? "season" : "single");
	// const { episodeNumber } = program?.episodeInfo || program;
	const title = program?.title || program?.originalTitle;
	const episode = upcomingBroadcasts.episodes.find(e => parseInt(e.programId) === parseInt(id));
	const broadcast = episode && episode.broadcasts.find(br => br.status === "firstRun");
	const excludeFirstrunBroadcastFromList = true;
	const onlyShowVideo = true;

	return (
		<div className="program-part">
			{getMainResponsiveImage(program, null, onlyShowVideo, allowPreview, onVideoClick, texts, pageName)}
			<div>
				<p>{title}</p>
				{broadcast && (
					<p>
						{getBroadcastText(broadcast.public.start, texts, timezone)}
						<span className={`channel-logo channel-${broadcast.channel.name}`}></span>
					</p>
				)}
			</div>
			{getSynopsis(program, false, undefined, texts)}
			{renderBroadcasts(program, upcomingBroadcasts, texts, timezone, excludeFirstrunBroadcastFromList)}
		</div>
	);
};

// When rendering seasons
const SeasonPart = ({
	program,
	// episodes,
	seriesId,
	seasonId,
	seasons: seriesSeasons,
	getSeasonText,
	texts,
	provider,
	getProgramLinkOverride,
}) => {
	const seriesSeasonsOrFallback = seriesSeasons?.length > 0 ? seriesSeasons : [program]; // If there is no series (or series.seasons), use a list of just the current season
	const seasons = getSeasons(seriesSeasonsOrFallback, getSeasonText);
	// const { seasonNumber, numberOfEpisodes } = program.seasonInfo || program;
	const hideExtendedForSeason = false;
	const title = program?.title || program?.series?.originalTitle;
	const label = program?.label;
	return (
		<div className="season-part">
			{getMainResponsiveImage(program, provider)}
			<div className="program-title-wrapper">
				<h2 className="program-title">
					{title && <span>{title}</span>}
					{title && label && <span>&nbsp;-&nbsp;</span>}
					{label && <span>{label}</span>}
				</h2>
				<select value={seasonId} onChange={e => onSelectSeason(e, seriesId, getProgramLinkOverride)} disabled={seasons.length <= 1}>
					{seasons.map(season => (
						<option key={season.key} value={season.key}>{season.text}</option>
					))}
				</select>
			</div>
			{getSynopsis(program, false, undefined, texts, hideExtendedForSeason)}
		</div>
	);
};

// HELPERS
function getMainResponsiveImage(program = {}, provider, onlyShowVideo, allowPreview, onVideoClick, texts, pageName) {
	if (onlyShowVideo) {
		const video = program?.assets?.find(a => a.category === "press" && a.type === "video");
		return video && (
			<div className="main-image">
				<div className="dummy-image"></div>
				{!allowPreview && texts["pressPreviewsNotAllowed"] && <div className="download-not-allowed">{texts["pressPreviewsNotAllowed"]}</div>}
				<div
					className={`video-indicator icon-play_circle_outline ${allowPreview ? "playable" : ""}`}
					onClick={allowPreview ? e => onVideoClick(e, video, pageName) : null}
				/>
			</div>
		);
	}

	const image = program?.assets?.find(a => a.category === "main" && a.type === "image")
		|| program?.series?.assets?.find(a => a.category === "main" && a.type === "image");

	return image && (
		<div className="main-image">
			{provider === "Hayu" && <span className="channel-logo channel-hayu" />}	
			<ResponsiveImage
				id={image.id}
				sizes="(min-width: 985px) 33vw, (min-width: 665px) 50vw, 100vw"
				extension={["png", "tif"].includes(image.ext?.toLowerCase()) ? "png" : "jpg"}
			/>
		</div>
	);
}

function getEpisodes({ episodes = [], episodeId, seasonId, seriesId, texts, getProgramLinkOverride, programSchedules, timezone, series, season }) {
	const upcomingBroadcasts = getUpcomingBroadcasts(programSchedules, "season");
	function handleSelectEpisode(episodeId) {
		browserHistory.push((getProgramLinkOverride || getProgramLink)(seriesId, seasonId, episodeId));
	}

	const episodesWithFirstRun = episodes
		.map(episode => {
			const brEpisode = (programSchedules.episodes || []).find(e => e.programId === episode.id);
			const firstRunBroadcast = brEpisode?.broadcasts.find(br => br.status === "firstRun");
			return {
				...episode,
				firstRunBroadcast,
			};
		})
		// Don't show sport episode without upcoming broadcast
		.filter(episode => {
			const brEpisode = upcomingBroadcasts.episodes.find(e => e.programId === episode.id);
			return brEpisode?.broadcasts.length > 0;
		})
		// Order by first upcoming broadcast
		.sort((episodeA, episodeB) => {
			const brEpisodeA = upcomingBroadcasts.episodes.find(e => e.programId === episodeA.id);
			const brEpisodeB = upcomingBroadcasts.episodes.find(e => e.programId === episodeB.id);
			return moment(brEpisodeA.broadcasts[0].public.start).unix() - moment(brEpisodeB.broadcasts[0].public.start).unix();
		});

	if (!episodesWithFirstRun.length) {
		return (
			<article className="no-episodes disabled"><p><em>{texts["noEpisodesAvailable"] || "Inga planerade avsnitt."}</em></p></article>
		);
	}

	return episodesWithFirstRun.map(episode => {
		const { id, versions, title, firstRunBroadcast } = episode;

		const episodeBroadcasts = (upcomingBroadcasts.episodes.find(e => e.programId === id) || {}).broadcasts;
		const broadcast = episodeBroadcasts && episodeBroadcasts[0];

		// let longSynopsis = "";
		let shortSynopsis = "";
		const synopsis = versions?.[0]?.synopsis || episode.synopsis;
		if (synopsis) {
			const { extended, long, medium, brief, short } = synopsis;
			// longSynopsis = extended || long || medium || brief || short || <em>{texts["noEpisodeSynopsis"] || "Ingen episodsynopsis."}</em>;
			shortSynopsis = short || brief || medium || long || extended;
		}

		const className = id === episodeId ? "sel" : null;

		const shouldShowFirstRunBroadcastDay = broadcast && broadcast.status !== "firstRun"
			&& firstRunBroadcast && firstRunBroadcast.public.start !== broadcast.public.start
			&& season?.category === "match";

		return (
			<article key={id} className={className} onClick={handleSelectEpisode.bind(this, id)}>
				<div>
					<p>{title || series?.title || "- Episode and series title missing -"}</p>
					<p>{shortSynopsis}</p>
				</div>
				<div>
					{broadcast && moment(broadcast.public.start).tz(timezone).format(`HH:mm ${appConfig.features.pressDateMonthFormat ?? "D/M"}`)}
					{shouldShowFirstRunBroadcastDay && <div className="dimmed">from {moment(firstRunBroadcast.public.start).tz(timezone).format(appConfig.features.pressDateMonthFormat ?? "D/M")}</div>}
					{/*broadcast && broadcast.status !== "firstRun" && <div className="dimmed">rerun</div>*/}
				</div>
			</article>
		);
	});
}

function getSeasons(seriesSeasons, getSeasonText) {
	return seriesSeasons.map(season => {
		const { id, versions = [], seasonInfo, label } = season;
		const seasonTitle = getSwedishVersion(versions).title || season.title;
		const { productionYear, seasonNumber } = seasonInfo || season;

		return {
			key: id,
			text: getSeasonText
				? getSeasonText(seasonTitle, label, productionYear, seasonNumber)
				: seasonTitle || label || (productionYear || seasonNumber ? `SÄSONG ${productionYear || seasonNumber} SAKNAR Season Label` : "?"),
		};
	});
}

function onSelectSeason(e, seriesId, getProgramLinkOverride) {
	const seasonId = e.target.value;
	browserHistory.replace((getProgramLinkOverride || getProgramLink)(seriesId, seasonId));
}