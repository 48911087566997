import React from 'react'

import InformationBox from '../widgets/informationBox'
import ResponsiveImage from '../../../components/assets/responsiveImage'
import BitmovinPlayer from '../../../components/bitmovin-player'

import Actions from '../actions'
import { isLoggedIn, getUser } from '../../../core/services/auth'
import { getSources } from '../../../components/assets/asseteditor/videoAsset'

import Translations from '../shared/translations'

import appConfig from 'config'

import './assetPreview.css'

export default class AssetPreview extends React.Component {

	state = {
		sources: null,
		errorMessage: "",
	}

	componentDidUpdate(prevProps) {
		if (!this.props.asset?.id && this.props.asset?.id !== prevProps.asset?.id) {
			this.setState({
				sources: null,
				errorMessage: "",
			});
			return;
		}

		if (
			isLoggedIn()
			&& (this.props.asset?.id !== prevProps.asset?.id)
			&& (this.props.asset?.type === "video" || this.props.asset?.type === "clip")
		) {
			this.fetchVideoSources();
		}
	}

	fetchVideoSources = async () => {
		const sources = await getSources({ assetGuid: this.props.asset.id, type: this.props.asset.type });
		this.setState({
			sources,
		});
	}

	render() {
		if (!this.props.asset?.id) {
			return null;
		}

		const content = getContent(this.props.asset, this.state.sources, this.state.errorMessage, this.props.assetInfoForAnalytics);
		return (
			<div className="c6-press-asset-preview" onClick={onWrapperClick}>
				<div className={`asset ${this.props.asset?.type ?? ""}`} onClick={onAssetClick} data-loadingtext={`${Translations.getTranslation("loading")}...`}>
					<div className="preview-close" onClick={onWrapperClick}><i className="icon-close"></i> {Translations.getTranslation('closePreview')}</div>
					{content}
				</div>
			</div>
		);
	}
}

function onWrapperClick() {
	Actions.previewAsset(null);
}

function onAssetClick(e) {
	e.stopPropagation();
}

function getContent(asset, sources, errorMessage, assetInfoForAnalytics) {
	if (errorMessage?.includes?.("does not have permission")) {
		return <p className="failed">{appConfig.features.pressUnauthorizedAssetMessage ?? "You do not have access to preview this asset."}</p>;
	}

	switch (asset.type) {
		case "video":
		case "clip":
			if (errorMessage?.length) {
				return <p className="failed">Failed to load video</p>;
			}
			let watermark;
			if (appConfig.features.pressAddVideoWatermark) {
				watermark = getWatermark();
			}
			return (
				<React.Fragment>
					<div className="disclaimer">
						<InformationBox
							name="press-preview-disclaimer"
							contentIfWidgetLoadFailed={<p>It is forbidden to share screenshots of these videos. Please read more at our <a className="c6-link" href="/faq">FAQ</a></p>}
						/>
						{/* <p>Esikatselutallenteiden ohjelmasisältö on suojattu tekijänoikeuslain perusteella ja tallenteet on tarkoitettu ainoastaan medialle tiedotus- ja arvostelutarkoituksia varten, luethan käyttöehdot <a href="https://pressi.mtv.fi/ukk">UKK-osiosta</a>.</p> */}
					</div>
					<BitmovinPlayer
						sources={sources}
						autoplay={true}
						watermark={watermark} // not implemented in our BitmovinPlayer component ?
						assetInfoForAnalytics={assetInfoForAnalytics}
					/>
				</React.Fragment>
			);
		case "image":
		default:
			return (
				<ResponsiveImage
					id={asset.id}
					format="fit"
					extension={["png", "tif"].includes(asset.ext?.toLowerCase()) ? "png" : "jpg"}
				/>
			);
	}
}

function getWatermark() {
	const user = getUser();
	const name = user.email ?? user.username;

	// Using DJB (or DJB2?) because its simple https://www.partow.net/programming/hashfunctions/#DJBHashFunction
	const chars = name.split("").map(s => s.charCodeAt(0));
	return chars.reduce((acc, cur) => ((acc << 5) + acc) + cur, 5381).toString();
}