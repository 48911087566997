import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import { useQuery } from '@tanstack/react-query'
import MiniSearch from 'minisearch'
import moment from 'moment'

import { SearchItem } from '../pages/search'

import Translations from '../shared/translations'
import * as PressAPI from '../../../apis/press'
import { getProgramLink } from '../shared/utils'

import './search.css'

export default class PressSearch extends Component {

	state = {
		searchText: "",
	}

	onContainerClick = e => {
		if (this.input) {
			this.input.focus();
		}
		if (this.props.onSearchClick) {
			this.props.onSearchClick(e);
		}
	}

	onClearClick = e => {
		e.stopPropagation();
		this.setState({ searchText: "" });
		if (this.input) {
			this.input.blur();
			this.input.value = "";
		}
	}

	onSearchResultClick = e => {
		e.stopPropagation();
		this.setState({ searchText: "" });
		if (this.input) {
			this.input.blur();
			this.input.value = "";
		}
	}

	search = e => {
		if (e.type === "keydown" || e.target.value.length === 0) {
			this.searchWithDebounce.cancel(); // Cancel the debounced call since we're doing it right now
			this.handleSearch(e);
		} else {
			e.persist();
			this.searchWithDebounce(e);
		}
	}

	handleSearch = e => {
		const searchText = e.target.value;
		this.setState({ searchText });
	}

	searchWithDebounce = debounce(this.handleSearch, 500);

	render() {
		const { searchText } = this.state;
		const content = (
			<>
				<div className="input-wrapper">
					<SearchIcon />
					<input
						type="search"
						placeholder={`${Translations.getTranslation("search")}...`}
						onChange={this.search}
						defaultValue={searchText}
						ref={el => this.input = el}
						maxLength={100}
					/>
					<button onClick={this.onClearClick}>×</button>
				</div>
				<div className="results">
					<SearchResults
						searchText={searchText}
						onSearchItemClick={this.onSearchResultClick}
					/>
				</div>
			</>
		);

		let className = "c6-press-search c6-press-search-new";
		if (!searchText || !searchText.length) {
			className += " empty";
		}

		return (
			<div className={className} onClick={this.onContainerClick}>
				{content}
			</div>
		);
	}
}

const SearchIcon = () => (
	<svg viewBox="0 0 24 24">
		<title>{Translations.getTranslation("search")}</title>
		<path d="M19.4,20.8c0.6,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l-4.3-4.3c-0.4-0.4-1-0.4-1.4,0c-2.2,2.2-5.8,2.2-8,0  s-2.2-5.8,0-8s5.8-2.2,8,0c1.5,1.5,2,3.7,1.4,5.7c-0.1,0.5,0.2,1.1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.6c1.3-4-0.9-8.3-4.9-9.6  S4.4,4.5,3.2,8.5s0.9,8.3,4.9,9.6c2.4,0.8,5,0.3,7-1.2l3.6,3.6C18.9,20.7,19.1,20.8,19.4,20.8z"></path>
	</svg>
);

function SearchResults({ searchText, onSearchItemClick }) {
	const a2zData = useA2Z();
	const searchResults = getSearchResults(searchText, a2zData);
    let content = null;
    if (!searchResults.length) {
        content = (
            <div className="search-status">{Translations.getTranslation("noItemsFound")}</div>
        );
    } else {
        content = searchResults?.map((item) => {
            const image = item.program?.assets?.find(a => a.type === "image" && a.contentType === "Series")
                ?? item.program?.assets?.find(a => a.type === "image" && a.contentType === "Single");
			
			let title = item.program?.seriesTitle ?? item.program?.title;
			let imageTopText = "";
			let imageBottomText = "";
			let hoverTitle = title;
			if (isEventOrSport(item)) {
				title = item.program?.seriesTitle;
				imageTopText = item.start ? moment(item.start).format("DD.MM. HH:mm") : "";
				if (item.program?.title !== item.program?.seriesTitle) {
					imageBottomText = item.program?.title;
					hoverTitle = `${item.program?.seriesTitle} - ${item.program?.title}`;
				}
			}

            return (
                <SearchItem
                    key={item.id}
                    link={getProgramLink(item.program?.seriesId, item.program?.seasonId, item.program?.programId ?? item.program?.episodeId)}
                    title={title}
					hoverTitle={hoverTitle}
                    image={image}
					onClick={onSearchItemClick}
					imageTopText={imageTopText}
					imageBottomText={imageBottomText}
                />
            );
        });
    }

    return (
        <div className="c6-press-search-searchresults">
            {content}
        </div>
    );
}

function useA2Z() {
    const { data } = useQuery(
        ["c6-press-search", "a2z"],
        () => {
            return new Promise((resolve) => {
                (async () => {
					const sportListNames = ["live-sport-mtv"];
					const sportLists = await Promise.all(sportListNames.map(name => PressAPI.fetchWidget({ widgetName: name })));
                    const lists = [
						...await PressAPI.fetchA2Z(),
						...sportLists,
					];

                    const flatList = lists?.reduce((result, list) => {
                        if (list?.items?.length) {
                            return [...result, ...list.items];
                        }
                        return result;
                    }, []);
                
                    resolve(flatList ?? []);
                })();
            });
        },
    );

    return data;
}

function getSearchResults(searchText, data) {
    if (!searchText?.length) {
        return [];
    }

    const text = searchText.toLowerCase();

    const miniSearch = new MiniSearch({
        fields: ["programTitle", "seriesTitle", "programCreditsName", "programSynopsisLong", "programSynopsisMedium", "programSynopsisShort"],
        extractField: (document, fieldName) => {
            if (fieldName === "programTitle") {
                return document.program?.title;
            }

			if (fieldName === "seriesTitle") {
				return document.program?.seriesTitle;
			}

            if (fieldName === "programCreditsName") {
                return document.program?.credits?.map(c => c.name).join(" ");
            }

            if (fieldName === "programSynopsisLong") {
                return document.program?.synopsis?.long;
            }

            if (fieldName === "programSynopsisMedium") {
                return document.program?.synopsis?.medium;
            }

            if (fieldName === "programSynopsisShort") {
                return document.program?.synopsis?.short;
            }

            return document[fieldName];
        },
        storeFields: ["id", "program", "start"],
        searchOptions: {
            boost: { programTitle: 1000, seriesTitle: 500, programCreditsName: 100, programSynopsisLong: 1, programSynopsisMedium: 1, programSynopsisShort: 1 },
            prefix: true,
            combineWith: "AND",
        }
    });

    miniSearch.addAll(data ?? []);

    const results = miniSearch.search(text)
		.filter((item, i, list) => {
			// Remove duplicates, since there can be two broadcasts of the same event but on different channels
			if (isEventOrSport(item)) {
				return list.findIndex(r => r.program?.episodeId === item.program?.episodeId) === i;
			}

			return true;
		})
		.sort((a, b) => {
			if (isEventOrSport(a) && isEventOrSport(b)) {
				return moment(a.start).diff(b.start);
			}

			return 0;
		});
    
    return results;
}

function isEventOrSport(item) {
	return ["Event", "Match"].includes(item?.program?.category);
}