import * as ContentAPI from '../../../apis/content'
import { useQuery } from '@tanstack/react-query'
// @ts-ignore
import appConfig from 'config'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useContentVodPackages = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
        apiKey: appConfig.api.contentKey,
    };
    return useQuery(
        ["content", "vod-packages", filters],
        () => ContentAPI.fetchPackages(filters),
        {
            enabled,
            onError: () => {}, // Do nothing on error
        }
    );
};

export default useContentVodPackages;